import React, { useState } from "react";
import { useObjectStore } from "../zustand/objects";
import { Card, Button, Text, Group, FileButton, ActionIcon, Center } from '@mantine/core';

const PreviewSplash = () => {

  const {
    shapeHovered
  } = useObjectStore();

 

  const [splashClicked, setSplashClicked] = useState(false);

  const changeSplashClick = (e) => {
    setSplashClicked(e)
  }

        
        return ( <>

{(splashClicked) ? "" : 
<Card  style={{borderRadius:32, width: 500, padding:0, backgroundColor: "rgb(105,105,105, 0.25)",
        height: 320,  top: '50%', overflow:'hidden',
        left: '50%',position: 'absolute',transform: 'translate(-50%, -50%)'}}>
          <Center inline>
          <div style={{height:20, marginTop:120}}>Click dot to preview. Hit 'Esc' to use mouse.</div></Center>
        </Card>}
        <div className={shapeHovered ? "dot-hover" : "dot"} id={"splashy"} onClick={() => changeSplashClick(true)} />
        </>
          




        //  {/* {(splashClicked) ? "" : <div style={styles.splash}><p style={styles.splashText}><strong>Click dot to preview. Hit 'Esc' to use mouse.</strong></p>
        //  </div>}
        // <div className={shapeHovered ? "dot-hover" : "dot"} id={"splashy"} onClick={() => changeSplashClick(true)} /> */}
       
      )}

const styles = {
  splash: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0%",
    left: "0%",
    outline: "none",
    background: "black",
    opacity: "80%"
  },
  splashText: {
    color: "white",
    textAlign: "center",
    position: "absolute"
  },
  splashButton: {
    position: "absolute",
    width: "10%",
    height: "5%",
    left: "45%",
    top: "47.5%"
  },
  wasdImage: {
    width: "12.5%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  button: {
    position: "absolute",
    width: "10%",
    height: "10%",
    top: "50%",
    left: "50%",
    color: "white",
  }
};

export default PreviewSplash;
