
import React, { useRef } from 'react'
import { Cylinder, useTexture, Edges } from '@react-three/drei'

export function CylinderShape({ ...props }) {
    const group = useRef()

    return ( 
        <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
            props.clickFunction()}}>
            <Cylinder position={[0,0.5,0]} args={[1, 1, 1, 30]} radius={0.05} smoothness={4} >
                <meshPhongMaterial
                    receiveShadow
                    color={props.colour}
                    attach="material"
                    opacity={1}
                />
                <Edges
                    scale={1}
                    threshold={15} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
                    color="#B2BEB5"
                />
            </Cylinder>
        </group> 
    )
}
export function CylinderShapeTexture({ ...props }) {

    const texture = useTexture(props.imageTexture)
    const group = useRef()

    return ( 
        <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
            props.clickFunction()}}>
            <Cylinder position={[0,0.5,0]} args={[1, 1, 1, 30]} radius={0.05} smoothness={4} >
                <meshPhongMaterial
                    receiveShadow
                    color={props.colour}
                    attach="material"
                    opacity={1}
                    map={texture}
                />
                <Edges
                    scale={1}
                    threshold={15} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
                    color="#B2BEB5"
                />
            </Cylinder>
        </group> 
    )
}
