import React from "react";
import AppNavBar from "../components/AppNavBar";

import { Link } from "react-router-dom";
import { useAccountStore } from "../zustand/account";
import Container from '@mui/material/Container';
import { Center, Button } from '@mantine/core';
import { Canvas, useThree } from "@react-three/fiber";
import { Text3D, Stage, OrbitControls  } from "@react-three/drei";



export default function NotFoundPage() {

    const GridBackground = () => {
        return (
            <div style={{ position: "absolute", left: 0, top: 0, bottom: 0, zIndex: -1, width: "100%", height: "100%", backgroundImage: 'url("/image/background-grid.png")', backgroundAttachment: "fixed" }}></div>

        ) 
    }

  const { 
    userStatus
  } = useAccountStore();

    return (<>
    <AppNavBar pageName={"Page not found"} />
        <Container component="main" maxWidth="lg">
            <GridBackground />
            <Canvas
                style={{
                    position: "fixed",
                    width: "90%",
                    height: "100%"
                }}>
                <OrbitControls enableZoom={false} enablePan={false} />
                <Stage adjustCamera intensity={1}>
                    <Text3D
                        curveSegments={32}
                        bevelEnabled
                        bevelSize={0.04}
                        bevelThickness={0.1}
                        height={1}
                        lineHeight={4}
                        letterSpacing={-0.06}
                        size={4}
                        font="/Chekgu_Oke_Regular.json">
                        {`404`}
                        {/* {`not\nfound!`} */}
                        <meshNormalMaterial color="#fac710" />
                    </Text3D>
                </Stage>
            </Canvas>
            {/* <Center>{userStatus === "loggedOut" ? 
            <Button
            component="a"
            href="/login"
            style={{
              width: 160, marginTop: 5, marginRight:20, marginBottom: 20, paddingBottom: 1, inline: true, backgroundColor: 'black', borderRadius: 32
            }}>Login</Button> : 
            <Button
            component="a"
            href="/projects"
            style={{
              width: 160, marginTop: 5, marginRight:20, marginBottom: 20, paddingBottom: 1, inline: true, backgroundColor: 'black', borderRadius: 32
            }}>Projects</Button>}</Center> */}
        </Container></>
    )
}