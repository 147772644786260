

import React, { useRef } from 'react'
import { Box, useTexture } from '@react-three/drei'

export function Toolbar({ ...props }) {
    const group = useRef()

    return (
        <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
            props.clickFunction()}}>
            <Box position={[0,0.5,0]} rotation={[0,0,0]} args={[1, 0.2, 0.05]} radius={0.05} smoothness={4} >
                <meshPhongMaterial
                    receiveShadow
                    color={props.colour}
                    attach="material"
                    opacity={1}
                    
                />
            </Box>
        </group>
    )
}
 
export function ToolbarTexture({ ...props }) {

    const texture = useTexture(props.imageTexture)
    const group = useRef()

    return (
        <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
            props.clickFunction()}}>
            <Box position={[0,0.5,0]} rotation={[0,0,0]} args={[1, 0.2, 0.05]} radius={0.05} smoothness={4} >
                <meshPhongMaterial
                    receiveShadow
                    color={props.colour}
                    attach="material"
                    opacity={1} transparent
                    map={texture}

                    
                />
            </Box>
        </group>
    )
}
 