import React, {useEffect} from "react";
import { getStripeCheckoutURL} from "../api";
import { LoadingOverlay } from '@mantine/core';

export default function StripeRedirectPage() {

const urlParams = new URLSearchParams(window.location.search);
const plan = urlParams.get('plan');

  const handleSubscriptionClick = (label) => {
    getStripeCheckoutURL({
        success_url: 'https://mbxr.io/projects',
        cancel_url: 'https://mbxr.io/membership',
        // success_url: 'http://localhost:3000/projects',
        // cancel_url: 'http://localhost:3000/membership',
        subscription: label
        // line_items: [
        //     {
        //       "adjustable_quantity": {
        //         "enabled": "false"
        //       },
        //       "price": "price_1OWmvWCjfvkdcEzn5YLmkdEC",
        //       "quantity": 1
        //     }
        //   ]
    }).then((res) => window.location.replace(res.url))   
}

useEffect(() => {
  handleSubscriptionClick(plan)
},[])

  return (
    <div style={{width:"100%", height:"100%"}}>
    <LoadingOverlay loaderProps={{ color: 'black' }} visible={true} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
    </div>
  )
};



