/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, Edges } from '@react-three/drei'
import * as THREE from "three";


export default function ModelObject({ ...props }) {
  const group = useRef()
  // const { nodes, materials } = useGLTF(props.url)
  const { nodes, materials } = useGLTF(`https://x1hz-knoc-qcpv.e2.xano.io${props.pose.modelPath}`)
  // const { nodes, materials } = useGLTF(`/model/preset_models/${props.pose}-transformed.glb`)



  const material = new THREE.MeshStandardMaterial({
    color: props.colour,
    transparent: false, opacity: 1
  });


  return (
    <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {
      e.stopPropagation()
      props.clickFunction()
    }}
    >
      <group scale={1.1} position={[0, 0, 0]} ref={group} {...props} dispose={null}>
        <mesh geometry={nodes[props.pose.name] && nodes[props.pose.name].geometry} material={material} rotation={[0, Math.PI, 0]}>
          {nodes[props.pose.name] && <Edges
            scale={1}
            threshold={40} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
            color="#B2BEB5"
            // color="grey"
          />}
          </mesh>
      </group>
    </group>
  )
}





