import React, { useState, useEffect, Suspense } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';  import Typography from "@mui/material/Typography";
import MenuButton from "./MenuButton";
import {Card, Button, Text, Group, ActionIcon, Textarea, Stack, CloseButton } from '@mantine/core';
import { useAccountStore } from "../zustand/account"; 
import { getStripeCheckoutURL } from "../api"; 


export default function AppNavBar({pageName}) {

 
return (<Stack>
      <SubscribeReminderBar/>
      <Toolbar variant="dense" sx={{mt: 0}} >
                  <MenuButton pageName={pageName} sx={{ flexGrow: 1 }} />
            {(pageName === 'projects') ? <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
      Projects
      </Typography>
      :
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
      {pageName}
      </Typography>}
      </Toolbar>
            </Stack>
  );
} 

export const SubscribeReminderBar = () => {

      const {userStatus} = useAccountStore();
      const [display, setDisplay] = useState("none")

      useEffect(() => {
            (userStatus === 'loggedIn-none' || userStatus === 'loggedIn-canceled') ? setDisplay("flex") : setDisplay("none")
      },[])


      const handleSubscriptionClick = (label) => {

            getStripeCheckoutURL({
                success_url: 'https://mbxr.io/projects',
                cancel_url: 'https://mbxr.io/logging-out',
                // success_url: 'http://localhost:3000/projects',
                // cancel_url: 'http://localhost:3000/membership',
                subscription: label
            })
            // .then((res) => console.log(res.url))
            .then((res) => window.location.replace(res.url))}

      const buttonStyle = {
            paddingTop: 1, 
            paddingBottom: 1, 
            inline: true, 
            fontSize: 12, 
            backgroundColor: 'black', 
            borderRadius: 32
      }

      const style = {
      top: 0,
      left: 0,
      width: '100%',
      height: 47.5,
      zIndex: 10,
      backgroundColor: '#fac710'} // Use Mantine's theme colors

const closeClick = () => {
      setDisplay("none")
}
            return (
                  
                        <Group pl={30} pr={30} display={display} style={style} justify={"space-between"}>
                              <Group>
                              <Text ml={50}>
                                    You are on a limited free plan. Upgrade for more than one project.
                              </Text>
                              <Button onClick={() => handleSubscriptionClick("pioneer")} style={buttonStyle} ml={10}>Upgrade</Button >
                              </Group>
                              {/* <CloseButton mr={50} style={{cursor:"pointer"}} onClick={closeClick} /> */}
                        </Group>
            )
}