import * as React from 'react';
import Navbar from "react-bootstrap/Navbar"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Col from "react-bootstrap/Col"
import Button from '@mui/material/Button';

const Footer = ({primary}) => {

    const styles = {
    

        color: "white",
        marginRight: "10px",
        textTransform: 'none',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
          color: "white",
        },
  
    };
  
    return (<>
      <style type="text/css">
        {`
        @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap');
        .custom-footer-brand {
        display: block;
      width: 240px;
      margin: 0px;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 800;
      font-size: 34px;
      color: white !important;
        }
  
        .customFooterBG {
          background-color: ${primary};
          color: white;

        }
  
        .customFooterbody {
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: white !important;
          opacity: 1;  
        }
  
  
     
   
      
      `}
      </style>
      <Navbar expand="lg">
        <Container>

              <div >© 2022 All Copyright to Matchbox XR Ltd. All rights reserved</div>

        </Container>
      </Navbar>
    </>
  );
}

export default Footer;