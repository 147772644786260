import React, { useState, useEffect } from 'react';
import { useObjectStore } from "../zustand/objects";
import { setSpecificUserProjectGuestAccessAPI, getSpecificUserProjectGuestAccessAPI } from "../api";
import { Grid, Modal, Typography } from "@mui/material/";
import { Group, Spoiler, Card, Button, Text, Switch, TextInput, ActionIcon } from '@mantine/core';
import {getStripeSubStatus, getProjectGuestsAPI, saveUserGuestsAPI, setGuestProjectsAPI, deleteGuestProjectsAPI, getGoogleAuthURL, getMiroAuthURL } from "../api";

import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function ShareModal({ projectKey }) {
 
    const {
        switchShareModalOpen,
        shareModalOpen
    } = useObjectStore();

    const onShareCancel = () => {
        switchShareModalOpen()
    }

    const [guests, setGuests] = useState([])

    const getGuests = () => {
        getProjectGuestsAPI(projectKey).then(res => res && res.projectGuests.length > 0 ? setGuests(res.projectGuests) : setGuests([])
        )
    }

    const [loggedOutAccess, setLoggedOutAccess] = useState(false)
    const [subscription, setSubscription] = useState(null)

    useEffect(() => {
        getStripeSubStatus().then((res) => setSubscription(res.plan))
        getGuests()
        getSpecificUserProjectGuestAccessAPI(projectKey)
        .then(res => setLoggedOutAccess(res))
    }, [])

    async function setNewGuests(newUser) {
        setGuests([guests.push(newUser)]) 
    }

    const addGuestAccess = (newUser) => {
        setGuestProjectsAPI({userEmail: newUser, projectKey: projectKey})
 }
    const submitUsers = (newUser) => {
        setNewGuests(newUser).then((e) =>
            saveUserGuestsAPI({
                projectKey: projectKey,
                projectGuests: guests
            })
            .then(r =>
                getProjectGuestsAPI(projectKey).then(res => {
                    setGuests(res.projectGuests)
                }
                )
            .then(r =>
                addGuestAccess(newUser)
                )
            ))
    } 

    const removeGuestUser = (email) => {
        
        const updatedGuests = guests.filter(guest => guest !== email);
        // const updatedGuests = guests.filter(guest => guest.email !== email);
        setGuests(updatedGuests)
        saveUserGuestsAPI({
            projectKey: projectKey,
            projectGuests: updatedGuests
        })
        deleteGuestProjectsAPI({
            userEmail: email,
            projectKey: projectKey
        })

    }

    const updateGuestAccess = (email, newAccess) => {
        const updatedGuests = guests.map(guest => {
          if (guest.email === email) {
            return {...guest, access: newAccess}; 
          }
          return guest; // Return the original user object
        });
        setGuests(updatedGuests); // Update the state variable with the new array of users
        saveUserGuestsAPI({
            projectKey: projectKey,
            projectGuests: updatedGuests
        });
      }

    const accessOptions = ['preview', 'edit']

    const handleChangeLoggedOutValue = (e) => {
        setSpecificUserProjectGuestAccessAPI({
            projectKey: projectKey,
            loggedOutGuestAccess: e.target.checked})
            setLoggedOutAccess(e.target.checked)
    }



    return (
        <Modal
            open={shareModalOpen}
            onClose={onShareCancel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Card style={{borderRadius:32, width: 500, paddingLeft:0, 
        height: 320,  top: '50%',
        left: '50%',position: 'absolute',transform: 'translate(-50%, -50%)'}}>
          
                <Grid container style={{marginBottom:10, height:240,  marginLeft: 20,overflow:'scroll'}}>
                    <Grid  item xs={12}>
                    <Text size="xl" fw={700}>Share this project</Text>
                    </Grid>
                    <Grid item xs={12} style={{  marginBottom: 0 }}>
                        <Spoiler maxHeight={20} showLabel="Show more" hideLabel="Hide" mb={40}>
                            <Text style={{ backgroundColor: "transparent" }} size='sm' >
                                This is a 3D ideas tool. It is not designed to secure sensitive content.
                                Avoid creating, uploading, or sharing sensitive content. <a href="https://www.matchboxxr.com/policies" target="_blank">Read more.</a></Text>
                        </Spoiler>
                    <Text size="md" fw={700}>Guest access</Text>
                    </Grid>
                    <Grid item xs={12} style={{marginBottom:5}}>
                    <Text size="md" fw={400}>Max of 1 guest editor for this tier.</Text>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: 0 }}>
                        <form style={{width:"75%"}}
                         onSubmit={(e) => {
                            e.preventDefault()
                            submitUsers(e.target[0].value)}}>
                        <TextInput
                            disabled={(subscription === 'personal' && guests.length > 0) && true}
                            radius={32}
                            size="md"
                            placeholder="Guest editor email"
                            rightSectionWidth={100}
                            rightSection={
                                <Button
                                    style={{
                                        width: 150, inline: true, backgroundColor: 'black', borderRadius: 32, marginRight:3
                                    }} type="submit">
                                    Submit</Button>
                            }
                        />
                        </form>
                       
                </Grid>
                <Grid item xs={12}  style={{ marginBottom:20}}> 
                    {shareModalOpen && guests && guests.length > 0 ?
                        guests.map((mapped, i) =>
                            <div key={i} style={{ display: "flex", marginBottom:5 }}>
                                <AccountCircleIcon style={{marginRight:2}}/>{mapped} 

                                <ActionIcon
                                variant="transparent"
                                color="black"
                                    onClick={() => {
                                        removeGuestUser(mapped)
                                    }}
                                    size="sm">
                                    <CloseIcon />
                                </ActionIcon>
                            </div>
                        )
                        :
                        <Typography style={{ marginTop: 5 }} variant="body1">No guest editors.</Typography>}

                </Grid>
                </Grid>
                <Grid container style={{ marginTop:250, position:"absolute", borderTop: "solid", paddingTop:10}}>
                <Grid item xs={12} style={{marginLeft:20}} >
                    <Group>
                        <Switch
                        // style={{marginBottom:20}}
                            color="black"
                            fw={500} size={'md'}
                            label="Logged out preview"
                            onChange={(e) => handleChangeLoggedOutValue(e)} type="checkbox" name="curve" checked={loggedOutAccess && loggedOutAccess}
                        />
                      <Button
          style={{   width:160,paddingTop: 1, paddingBottom: 1, inline: true,  backgroundColor: 'black', borderRadius: 32
          }} onClick={() => navigator.clipboard.writeText(`https://mbxr.io/story/${projectKey}`)}>Copy preview link</Button>
</Group>
                </Grid>
 

                    

                </Grid>
                </Card>
            {/* </Box> */}
        </Modal>
    );
}


export const MiroShareButton = ({projectKey}) => {

    const popup = (url) => window.location.replace(url)
    const handleClick = () => {
        getMiroAuthURL(projectKey).then((res) => popup(res))
    }
    
    return (  
        <Button
        onClick={() => handleClick()}
        style={{
            width: 120, marginTop: 20, marginBottom:20,  paddingBottom: 1, inline: true, backgroundColor: 'black', borderRadius: 32
        }}>Link to Miro</Button>
    )
}

export const GoogleShareButton = ({projectKey}) => {

    const popup = (url) => window.location.replace(url)
    // const popup = (url) => window.open(url, "popup", "popup=true")

    const handleClick = () => {
        getGoogleAuthURL(projectKey).then((res) => popup(res))
    }

    return (  
        <Button 
        onClick={() => handleClick()}
        style={{
            width: 150, marginTop: 20, marginBottom:20,  paddingBottom: 1, inline: true, backgroundColor: 'black', borderRadius: 32
        }}>Link to Google</Button>
    )
}








