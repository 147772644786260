/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, Suspense, useState, useEffect } from 'react'
import { useGLTF, Backdrop, useTexture, Cylinder } from '@react-three/drei'
import * as THREE from "three";


 
export function CurvedPanels({ ...props }) { 

  const material = new THREE.MeshBasicMaterial({
    color: props.colour,
    transparent: false, opacity: 1
});

    const group = useRef()
    const { nodes, materials } = useGLTF('/model/curvedPanels-transformed.glb')
    return (
      <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
        props.clickFunction()}}>
          <group rotation={[0, Math.PI,0]} position={[0,0.5,1.125]}>
          <Cylinder
          material={new THREE.MeshStandardMaterial}
          material-side={THREE.DoubleSide}
          material-receiveShadow={true}
          material-opacity={1}
          material-color={props.colour}
          args={[1,1,1,30,1,true,-1.5,3]}          
          />
          </group>
    </group>
    )
  }  
  

  export function CurvedPanelsTexture({ ...props }) {
   
    const myImage = props.imageTexture


   
    
    const texture = useTexture(props.imageTexture)


    const material = new THREE.MeshBasicMaterial({
      color: props.colour,
      transparent: false, opacity: 1
  }); 

  

      const group = useRef()
      const { nodes, materials } = useGLTF('/model/curvedPanels-transformed.glb')
      return (
        <Suspense fallback={null}>
        <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} >
          <group rotation={[0,Math.PI,0]}  position={[0,props.height / 2,props.width]}>
             <Cylinder
          material={new THREE.MeshStandardMaterial}
          material-side={THREE.DoubleSide}
          material-receiveShadow={true}
          material-opacity={1}
          material-color={props.colour} 
          material-map={texture}
          material-transparent={true}
          args={[props.width ? props.width : 1, props.width ? props.width : 1, props.height ? props.height : 1, 30, 1, true, props.curveAmount ? props.curveAmount/4 : -1, props.curveAmount ? -props.curveAmount/4 * 2 : -2]}                   
          /> 
          </group>
      </group></Suspense>
      )
    } 
    