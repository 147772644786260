import React, {useEffect} from "react";
import Memberships from "../components/Memberships.js"
import { useAccountStore } from "../zustand/account";
import { getStripeCheckoutURL} from "../api";
import { LoadingOverlay, Card, Stack, Text, Group, Badge, Button, ActionIcon } from '@mantine/core';


export default function MembershipPage() {

    const { userStatus} = useAccountStore();

    const GridBackground = () => {
        return (
            <div style={{ position: "fixed", left: 0, top: 0, bottom: 0, zIndex: -1, width: "100%", height: "100vh", backgroundImage: 'url("/image/background-grid.png")', backgroundAttachment: "fixed" }}></div>

        )
    } 
 
    const urlParams = new URLSearchParams(window.location.search);
    const plan = urlParams.get('plan');

    const chooseRedirect = () => {
        // console.log(userStatus)
        // userStatus && userStatus === 'loggedIn-none' && handleSubscriptionClick("pioneer")
        userStatus && userStatus === 'loggedIn-none' ? handleSubscriptionClick("pioneer") : userStatus === 'loggedIn-active' ? window.location.replace('https://mbxr.io/projects') :  window.location.replace('https://mbxr.io/login')
    }

    useEffect(() => {
        chooseRedirect()
        // userStatus === 'loggedIn-active' ? window.location.replace('https://mbxr.io/projects') 
        // : getStripeCheckoutURL({
        //     success_url: 'https://mbxr.io/projects',
        //     cancel_url: 'https://mbxr.io/account',
        //     subscription: "pioneer"
        // }).then((res) => window.location.replace(res.url))

    },[])
  
    const handleSubscriptionClick = (label) => {

        getStripeCheckoutURL({
            success_url: 'https://mbxr.io/projects',
            cancel_url: 'https://mbxr.io/logging-out',
            // success_url: 'http://localhost:3000/projects',
            // cancel_url: 'http://localhost:3000/membership',
            subscription: label
        })
        // .then((res) => console.log(res.url))
        .then((res) => window.location.replace(res.url))

}

    

    return (
        <div>
            <GridBackground />

                <LoadingOverlay loaderProps={{ color: 'black' }} visible={true} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />

            {/* <Memberships open={true}/> */}
            
        </div>
    )
}