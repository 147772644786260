
import React, { useRef } from 'react'
import { Box, useTexture, Edges } from '@react-three/drei'


export function CubeShape({ ...props }) {
    const group = useRef()

    return (
        <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
            props.clickFunction()}}>
            <Box position={[0, 0.5, 0]} args={[1, 1, 1]} radius={0.05} smoothness={4} >
                <meshStandardMaterial
                    receiveShadow
                    attach="material"
                    color={props.colour}
                    opacity={1}
                />
                <Edges
                    scale={1}
                    threshold={15} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
                    color="#B2BEB5"
                />
            </Box>
        </group>
    )
}
export function CubeShapeTexture({ ...props }) {
    const texture = useTexture(props.imageTexture)
    const group = useRef()
    return (
        <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
            props.clickFunction()}}>
            <Box position={[0, 0.5, 0]} args={[1, 1, 1]} radius={0.05} smoothness={4} >
                <meshStandardMaterial
                    receiveShadow
                    attach="material"
                    color={props.colour}
                    opacity={1}
                    map={texture}
                />
                <Edges
                    scale={1}
                    threshold={15} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
                    color="#B2BEB5"
                />
            </Box>
        </group>
    )
}
