import React, { useState, useEffect } from "react";
import { MenuItem } from "@mui/material/";
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import { Select, Portal } from '@mantine/core';

import { useObjectStore } from "../zustand/objects";

const LinksToButton = ({ destination, id }) => {
 
  const [show, setShow] = useState(false);
  const [showText, setShowText] = useState(false);
  const [divWidth, setDivWidth] = useState(68);


 
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = ({ id }) => {
    setAnchorEl(null);
    setShow(false);
    setShowText(false);
  };


  const { artboards, updateDestination, currentObjectArtboard, arrangeUndoRedo } = useObjectStore();

  const handleSelect = (mappedItem) => {
    // send current state to undoActions
    arrangeUndoRedo({
      updateType: "objectUpdate", 
      artboard: currentObjectArtboard, 
      objectID: id,
      undo:false,
      field: "destination"
      })
      // send update to zustand
    updateDestination({
      id: id,
      currentObjectArtboard: currentObjectArtboard,
      destination: mappedItem,
      undo: false
    })
  }

  const style = {
    blockquote: {
      fontStyle: 'italic',
      fontSize: '.75rem',
    },
    label: {
      fontSize: '0.8rem',
      fontWeight: 'bold',
      lineHeight: 2
    },
  };

  const options = []

  const optionsMap = Object.keys(artboards).filter((mapped) => mapped !== currentObjectArtboard)
  


  return (

    <div style={{ height: "100%", width:85, display: 'flex', justifyContent: 'flex-start', zindex: 1600, overflow: 'visible' }}>
      <Select
        // style={{zIndex:2000, borderRadius: '16px', dropdown:{zIndex:2000}, options:{zIndex:2000}, group:{zIndex:2000}}}
        radius={16}
        size={'xs'}
        label="Links to scene"
        placeholder="Pick..."
        data={[...optionsMap]}
        clearable
        
        
        defaultValue={destination}
        onChange={(e) => e ? handleSelect(e) : handleSelect('')}
      />

    </div>


  );
};

export default LinksToButton;
