import React, { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import Input from '@mui/material/Input';

import FormControl from '@mui/material/FormControl';
import { useObjectStore } from "../zustand/objects";
import { Button } from '@mantine/core';


const ObjectTitleName = ({open, title, id, isLocked}) => {

  const [editing, setEditing] = useState(false)
  const { arrangeUndoRedo, updateObjectTitle, currentObjectArtboard, updateObjectTitleStatus, editingObjectTitle } = useObjectStore();

  const handleClick = () => {
    updateObjectTitleStatus(true)
    console.log(editingObjectTitle)
    // setEditing(true)
  }

  const handleSubmit = (val) => {
    // send current state to undoActions
    arrangeUndoRedo({
      updateType: "objectUpdate", 
      artboard: currentObjectArtboard, 
      objectID: id,
      undo:false,
      field: "objectTitle"
      })
      // send update to zustand
    updateObjectTitle(id, currentObjectArtboard, val, false)
    // setEditing(false)
    updateObjectTitleStatus(false)
    console.log(editingObjectTitle)

  }

  
  

  return (
    
      <>
 
      {editingObjectTitle ?
        <FormControl onBlur={(e) => handleSubmit(e.target.value)}>
          <Input  id="outlined-basic" defaultValue={title} autoFocus variant="outlined" onKeyPress={(e) => { if (e.key === 'Enter') { handleSubmit(e.target.value) } }} />
        </FormControl>
        :
        <><Button 
        style={{
          height: 28, width: 82, inline: true, marginTop: 5, fontSize: 12, backgroundColor: 'black',
          borderTopLeftRadius: 32, borderBottomLeftRadius: 32, borderTopRightRadius: 32, borderBottomRightRadius: 32
        }} 
       onClick={() => !isLocked && handleClick()} size={"small"} >
          Rename
          {/* {title} */}
        </Button>
        </>}  
        </>
 
  );
};


export default ObjectTitleName;
