

import React, { useRef } from 'react'
import { Sphere, useTexture, Edges } from '@react-three/drei'

export function SphereShape({ ...props }) {
    const group = useRef()

    return (
        <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
            props.clickFunction()}}>
            <Sphere position={[0, 0.5, 0]} radius={0.05} smoothness={4} >
                <meshPhongMaterial
                    receiveShadow
                    color={props.colour}
                    attach="material"
                    opacity={1}
                />
                <Edges
                    scale={1}
                    threshold={15} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
                    color="#B2BEB5"
                />
            </Sphere>
        </group>
    )
}


export function SphereShapeTexture({ ...props }) {

    const texture = useTexture(props.imageTexture)
    const group = useRef()

    return (
        <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
            props.clickFunction()}}>
            <Sphere position={[0, 0.5, 0]} radius={0.05} smoothness={4} >
                <meshPhongMaterial
                    receiveShadow
                    color={props.colour}
                    attach="material"
                    opacity={1}
                    map={texture}
                />
                <Edges
                    scale={1}
                    threshold={15} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
                    color="#B2BEB5"
                />
            </Sphere>
        </group>
    )
}
