import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes, Navigate } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute.js";
import { useAccountStore } from "./zustand/account";
import { useObjectStore } from "./zustand/objects";
import { useAuth0 } from '@auth0/auth0-react';
import EditorPage from "./pages/EditorPage";
import PreviewPage from "./pages/PreviewPage";
import SharePreviewPage from "./pages/SharePreviewPage";
import ProjectsPage from "./pages/ProjectsPage";
import AuthCallbackPage from "./pages/AuthCallbackPage";
import ErrorPage from "./pages/ErrorPage";
import EditorStoryboardPage from "./pages/EditorStoryboardPage";
import PreviewStoryboardPage from "./pages/PreviewStoryboardPage";
import LoginPage from "./pages/LoginPage";
import Footer from "./components/Footer";
import NotFoundPage from "./pages/NotFoundPage";
import SignUpPage from "./pages/SignUpPage";
import MiroBrowseProjectsPage from "./pages/MiroBrowseProjectsPage";
import {MiroAuthCallbackPage} from "./pages/MiroAuthCallbackPage";
import {GoogleAuthCallbackPage} from "./pages/GoogleAuthCallbackPage";
import MiroLoginPage from "./pages/MiroLoginPage";
import MembershipPage from "./pages/MembershipPage";
import Auth0CallbackPage from "./pages/Auth0CallbackPage";
import ProfileSettingsPage from "./pages/ProfileSettingsPage.js";
import StripeRedirectPage from "./pages/StripeRedirectPage.js";
import AccountPage from "./pages/AccountPage.js";
import LogMeOutPage from "./pages/LogMeOutPage.js";
import TemplatesPage from "./pages/TemplatesPage.js";

const App = () => {   

  const {
    checkAuthTokenValid,
    loginChecked,
    checkAuthTokenValidSubscription,
    isAuthUser,
    stripeStatus,
    validateAccess
  } = useAccountStore();
  
  const {
    isError
  } = useObjectStore();
  
  const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } =
  useAuth0()

  useEffect(() => { 
    checkAuthTokenValidSubscription()
  }, [])
 
  return loginChecked && <>

    <Routes> 
      <Route exact path='/login' element={<LoginPage />} />
      <Route exact path='/' element={<Navigate replace to="/projects" />}/>

      {/* LOGGED IN ONLY PAGES */}
      <Route element={<PrivateRoute />}>
      <Route path='/projects' element={<ProjectsPage/>}/> 
      </Route>
      <Route element={<PrivateRoute />}>
      <Route path='/profile' element={<ProfileSettingsPage/>}/> 
      </Route>
      <Route element={<PrivateRoute />}>
      <Route path='/auth-complete' element={<AuthCallbackPage/>}/> 
      </Route>
      <Route path='/miro-auth-complete' element={<MiroAuthCallbackPage/>}/> 
      <Route path='/google-auth-complete' element={<GoogleAuthCallbackPage/>}/> 
      <Route element={<PrivateRoute />}>
        <Route path='/editor/:projectKey' element={<EditorPage/>} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path='/storyboard-editor/:projectKey' element={<EditorStoryboardPage/>} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path='/preview/:projectKey' element={<PreviewPage/>} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path='/planSelected' element={<StripeRedirectPage/>} />
      </Route>
      {/* Page with links to templates */}
      <Route element={<PrivateRoute />}>
        <Route path='/templates' element={<TemplatesPage/>} />
      </Route>
      {/* Page with links to templates */}
      <Route element={<PrivateRoute />}>
        <Route path='/account' element={<AccountPage/>} />
      </Route>
      {/* Membership page now either redirects logged-in users to projects or pricing page in matchboxxr.com */}
      
      {/* LOGGED OUT PAGES */}
      <Route exact path='/story/:projectKey' element={<PreviewStoryboardPage />} />
      <Route exact path='/logging-in' element={<Auth0CallbackPage />} />
      <Route exact path='/share/:projectKey' element={<SharePreviewPage />} />
      <Route exact path='/membership' element={<MembershipPage />} />
      {/* <Route exact path='/account' element={<AccountPage />} /> */}
      {/* Log me out page to act as escape for Stripe */}
      <Route path='/logging-out' element={<LogMeOutPage/>} />
      <Route path='*' element={<NotFoundPage />}/>
      <Route path='/404' element={<NotFoundPage />}/>

    </Routes>




    
    <div style={{ position: "fixed", right: 0, bottom: 0, left: 300 }}>
     
      <Footer />
    </div>


  </>

};

export default App;

