import React, { useRef, useEffect, useCallback, useState } from "react";
import { PivotControls, Cylinder, Sphere, Box} from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useObjectStore } from "../zustand/objects";
import { useThree } from "react-three-fiber";
import debounce from 'lodash.debounce';
import * as THREE from "three";


export const Camera = ({ cameraSelected, position, rotation, scale, id, currentObjectArtboard, objectsAreLoaded, matrix }) => {
  

  const {
    updatePreviewCamera,
    previewCameraPosition,
    storyboardPOV,
    updateStoryboardPOV
  } = useObjectStore();


  // const pos = new THREE.Vector3(storyboardPOV[currentObjectArtboard].position[0], storyboardPOV[currentObjectArtboard].position[1], storyboardPOV[currentObjectArtboard].position[2])
  // const rot = new THREE.Quaternion(storyboardPOV[currentObjectArtboard].rotation[0],storyboardPOV[currentObjectArtboard].rotation[1],storyboardPOV[currentObjectArtboard].rotation[2])
  // const sca = new THREE.Vector3(1,1,1)
  // const [gizmoMatrix, setGizmoMatrix] = useState(new THREE.Matrix4().compose(pos, rot, sca))
  const [gizmoMatrixx, setGizmoMatrixx] = useState()
  const [cameraOn, setCameraOn] = useState(false)

// useEffect(() => {
// setGizmoMatrixx(new THREE.Matrix4().compose(pos, rot, sca))
//   },[storyboardPOV])
useFrame(() => {
  const pos = new THREE.Vector3(storyboardPOV[currentObjectArtboard].position[0], storyboardPOV[currentObjectArtboard].position[1], storyboardPOV[currentObjectArtboard].position[2])
  const rot = new THREE.Quaternion(storyboardPOV[currentObjectArtboard].rotation[0],storyboardPOV[currentObjectArtboard].rotation[1],storyboardPOV[currentObjectArtboard].rotation[2])
  const sca = new THREE.Vector3(1,1,1)
setGizmoMatrixx(new THREE.Matrix4().compose(pos, rot, sca))
  },[cameraOn])
  
  
  useEffect(() => {
    setCameraOn(cameraSelected)
    },[cameraSelected])



  const ObjectUpdate = () => {
    const pos2 = new THREE.Vector3()
    const rot2 = new THREE.Quaternion()
    const sca2 = new THREE.Vector3()
    const toDecompose = new THREE.Matrix4().copy(camRef.current.matrixWorld)
    toDecompose.decompose(pos2, rot2, sca2)
    storyboardPOV && updateStoryboardPOV([pos2.x, pos2.y, pos2.z],[rot2.x, rot2.y, rot2.z], currentObjectArtboard)
    // updatePreviewCamera('rotation', [rot2.x, rot2.y, rot2.z, rot2.w])
  } 
 
  const camRef = useRef()


  if (!objectsAreLoaded) {
    return '';
  } else {
    return (

      <PivotControls
      fixed={true}
      scale={100}
      depthTest={false}
      lineWidth={5}
      visible={cameraOn} 
      // visible={selectedObjectID === id ? true : false} 
      ref={camRef} 
      onDragEnd={() => ObjectUpdate()}
      matrix={gizmoMatrixx}>
     { cameraOn && <group>
             <group scale={0.5} position={[0,1,0]}>
        <Sphere args={[0.45]} position={[0, 1.9, -0.12]} >
        <meshStandardMaterial
                    receiveShadow
                    color={"white"}
                    attach="material"
                    opacity={1}
                />
        </Sphere>
        <Box args={[.65,.23,.23]} position={[0, 1.91, -0.47]}>
        <meshStandardMaterial
                    receiveShadow
                    color={"grey"}
                    attach="material"
                    opacity={1}
                />
        </Box>
         <Cylinder args={[0.42, 0.25, 1, 30]} position={[0, 1.1, 0]} >
         <meshStandardMaterial
                    receiveShadow
                    color={"white"}
                    attach="material"
                    opacity={1}
                />
        </Cylinder>
        </group>
        </group>}
      </PivotControls>

    ); 
  }
};

// Can't use below without enabling Matrix4 on the camera first

export const GroupedCamera = ({ orbitControls, position, rotation, scale, id, currentObjectArtboard, objectsAreLoaded, matrixState }) => {
  

  const {
    updateObjectPosition,
    updateObjectRotation,
    updateObjectScale,
    updateObjectSelected,
    selectedObjectID,
    transformMode
  } = useObjectStore();

  const {scene} = useThree();

  const modelRef = useRef()

  useEffect(() => {
      Promise.resolve(scene.getObjectByName(id).remove(modelRef.current))
.then(Promise.resolve(modelRef.current.applyMatrix4(matrixState)))
.then(Promise.resolve(scene.getObjectByName(id).attach(modelRef.current)))
    },[]);



  if (!objectsAreLoaded) {
    return '';
  } else {
    return (
 
      <group
      ref={modelRef}
      scale={scale}
  >
        <group position={[0,0.15,0]}>
        <Sphere  args={[0.45]} position={[0, 1.9, -0.12]}>
        <meshStandardMaterial
                    receiveShadow
                    color={"white"}
                    attach="material"
                    opacity={1}
                />
        </Sphere>
        <Box  args={[.65,.23,.23]} position={[0, 1.91, -0.47]}>
        <meshStandardMaterial
                    receiveShadow
                    color={"grey"}
                    attach="material"
                    opacity={1}
                />
        </Box>
         <Cylinder args={[0.42, 0.25, 1, 30]} position={[0, 1.1, 0]}>
         <meshStandardMaterial
                    receiveShadow
                    color={"white"}
                    attach="material"
                    opacity={1}
                />
        </Cylinder>
        </group>
        </group>

    );
  }
};
