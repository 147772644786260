import React, { useState } from 'react';
import { useAccountStore } from "../zustand/account";
import { Grid, Button, Modal, Box, Typography } from "@mui/material/";
import Stack from '@mui/material/Stack';


export default function DeleteProjectModal({deleteID}) {

  const {deleteSpecificUserProject, updateDeleteModalOpen, deleteModalOpen, getUserProjects} = useAccountStore();

  const [textValue, setTextValue] = useState('')

  const onDeleteSubmit = () => {

      deleteSpecificUserProject(deleteID).then(() => getUserProjects())
      updateDeleteModalOpen()

  }

  const onDeleteCancel = () => {
    updateDeleteModalOpen()
  }

  const handleChange = (e) => {
    (textValue !== "") && setTextValue(e.target.value)
  }

  return (
    <Modal
      open={deleteModalOpen}
      onClose={onDeleteCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }}>
        <Grid container >
          <Grid item xs={12}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
          Are you sure you want to delete?
        </Typography>
        </Grid>
        <Grid item xs={12}>
        <Stack direction="row" spacing={2}>
        <Button style={{ backgroundColor: "#EFEFEF", boxShadow: "none", color: 'black', borderRadius: 0, borderStyle: "outset" }} size="small" variant="contained"
        onClick={() => onDeleteCancel()}>Cancel</Button>
        <Box component="form">
        <Button style={{ backgroundColor: "darkRed", boxShadow: "none", color: 'white', borderRadius: 0, borderStyle: "outset" }} size="small" variant="contained"
        onClick={() => onDeleteSubmit()}>DELETE</Button>
        </Box>
        </Stack>
        </Grid>
        
        </Grid>
      </Box>
    </Modal>
  );
}