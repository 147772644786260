import React, {useEffect} from "react";
import { Loader } from "@mantine/core"
import { getAuth0Token } from '../api.js'
import { useAuth0 } from '@auth0/auth0-react';
import { useAccountStore } from "../zustand/account";

const Auth0CallbackPage = () => {

  const {
    checkAuthTokenValid
  } = useAccountStore();
   
  

    const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } =
    useAuth0();
     

    // console.log("isAuthenticated" + isAuthenticated)

    let params = new URLSearchParams(document.location.search);

    const code = params.get("code");
    const uri = 'https://mbxr.io/logging-in'


  useEffect(() => {

    // getAuth0Token({code: code,redirect_uri: 'http://localhost:3000/logging-in'})
    getAuth0Token({code: code,redirect_uri: 'https://mbxr.io/logging-in'})
    .then((res) => localStorage.setItem('user',res.token))
    .then(() => window.location.replace('https://mbxr.io/projects'))
    // .then(() => window.location.replace('http://localhost:3000/projects'))
    // .then(() => checkAuthTokenValid())
  },[])


  // if (error) {
  //   return <div>Oops... {error.message}</div>;
  // }
return <>
<div style={{position:"absolute", left:"50%", top:"50%", width:"100%", height:"100%"}}><Loader color="black" /></div>

</>

  
};


export default Auth0CallbackPage;
