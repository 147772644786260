import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import { useNavigate } from "react-router-dom";

import {Button, Text, Title} from '@mantine/core';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { useAccountStore } from "../zustand/account"; 
  
import {getAuth0URL} from '../api.js'

const LoginButton = () => {
    // const { loginWithRedirect } = useAuth0();

    const handleLoginClick = () => {
        // getAuth0URL('http://localhost:3000/logging-in').then((res) => window.location.assign(res.authUrl))
        getAuth0URL('https://mbxr.io/logging-in').then((res) => window.location.assign(res.authUrl))
        

    }
  
    return  <Button  onClick={() => handleLoginClick()}
    size="xl" variant="contained"
    style={{margin:30, backgroundColor: "#FAC710", boxShadow: "none", color: 'black', borderRadius: 0, borderStyle: "outset", padding:4}}>
    Login/register</Button>
    

  };

const LoginPage = () => {

    const navigate = useNavigate();

    const { sendLoginInfo } = useAccountStore();

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        sendLoginInfo({
            email: data.get('email'),
            password: data.get('password')
        })
        .then(() => navigate("/projects"))
    };

    const GridBackground = () => {
        return (
            <div style={{ position: "absolute", left: 0, top: 0, bottom: 0, zIndex: -1, width: "100%", height: "100%", backgroundImage: 'url("/image/background-grid.png")', backgroundAttachment: "fixed" }}></div>

        )
    }



    return (
        <>
            <Container component="main" maxWidth="md">
                <CssBaseline />
                <GridBackground />
                <Box
                    sx={{
                        marginTop: 20,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <Box
                        mb={3}
                            component="img"
                            sx={{
                                maxHeight: { xs: 200, md: 134 },
                                maxWidth: { xs: 300, md: 200 },
                            }}
                            src="/matchboxxrLogo.png"
                        />
                    
                </Box>

                <Box
                    sx={{
                        marginTop: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    
                        

                    <Title order={1}>
                        Welcome to matchboxxr!
                    </Title>
                    
                    <LoginButton/>
                        <div style={{backgroundColor:"transparent"}}>
                                <Typography style={{backgroundColor:"transparent"}} fontWeight={'300'} >
                                    This is a 3D ideas tool. It is not designed to secure sensitive information.
                                </Typography>
                                <Typography style={{backgroundColor:"transparent"}} fontWeight={'300'} >
                                    This tool is in constant development. Your experience may vary and some data may be changed or lost.
                                </Typography>
                                <Typography style={{backgroundColor:"transparent"}} fontWeight={'300'} >
                                    <a href="https://www.matchboxxr.com/policies" target="_blank">Read our Privacy Policy and Terms for more info.</a>
                                </Typography>
                            </div>
                  
                </Box>
            </Container>
        </>

    )
};


export default LoginPage;