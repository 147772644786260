import React, { useEffect, Suspense, useState } from "react";
import {Loader, LoadingOverlay} from '@mantine/core';
 
import { Button, Grid } from "@mui/material/";
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import { useAccountStore } from "../zustand/account";
import { useObjectStore } from "../zustand/objects";
import { Typography } from '@mui/material';

import AppNavBar from "../components/AppNavBar";

import DeleteProjectModal from "../components/DeleteProjectModal";
// import Memberships from "../components/Memberships";
import ProjectsTable from "../components/ProjectsTable";
import {getGuestProjectsAPI} from "../api";

import { useAuth0 } from '@auth0/auth0-react';



const ProjectsPage = () => {

const [buttonLoaded, setButtonLoaded] = useState(false)

    // const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } =
    // useAuth0();

    // console.log(user)
 // need api set up first
  const [guestProjects, setGuestProjects] = useState()
  const [loading, setLoading] = useState(true)

  const getGuestProjects = () => {
    getGuestProjectsAPI().then(res => setGuestProjects(res))
  }

    const { projects, createNewProject, getUserProjects, deleteID, userStatus } = useAccountStore();
    const { loadObjects, getAIModels, objectsAreLoaded } = useObjectStore();

    async function sendProject(e) {
        let projectsLength = projects.length
        createNewProject({ name: `Project ${projectsLength += 1}` })
    }

    async function getProjects() {
        getAIModels()
        getUserProjects()
        getGuestProjects()
        // setLoading(false)
    }

    const setLoader = () => {
        loadObjects()
        setLoading(false)
    }

    const handleSubmit = (e) => {
        // e.preventDefault(); 
        // unloadObjects()
        setLoading(true)
        sendProject(e)
        // .then(() => getUserProjects())
        .then(() => setTimeout(() => {
            getProjects();
          }, "400")) 
        // .then(() => getProjects()) 
        .then(() => setLoader())
        // .then(() => loadObjects())
    } 

    useEffect(() => {
        getProjects().then(() => setLoader())
    }, [])

    const GridBackground = () => {
        return (
            <div style={{ position: "fixed", left: 0, top: 0, bottom: 0, zIndex: -1, width: "100%", height: "100vh", backgroundImage: 'url("/image/background-grid.png")', backgroundAttachment: "fixed" }}></div>

        )
    }

    return (
        <div>
            <Suspense fallback={<div>Loading... </div>}>
                {/* <Memberships open={true}/> */}
            <DeleteProjectModal deleteID={deleteID} />
            <GridBackground />
            <Grid container>
                <Grid item xs={12}>
                    <AppNavBar pageName={'projects'} />
                    {/* <button onClick={() => methodDoesNotExist()}>Break the world</button>; */}
                </Grid>
                <Grid item xs={12} md={9} sx={{ mt: 8, mb: 4 }}>
                <Box sx={{ marginLeft: 10, flexGrow: 1 }}>
                <LoadingOverlay loaderProps={{ color: 'black' }} visible={loading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
 
                            <Typography component="h1" variant="h4" fontWeight={'700'}>
                                Your creations
                            </Typography>
                        </Box> 
                        </Grid>
                        <Grid item xs={12} md={3} sx={{ mt: 8, mb: 2 }}>
                        <Box sx={{  flexGrow: 1 }}>
                        
                        {projects ? (userStatus === 'loggedIn-none' || userStatus === 'loggedIn-canceled') ? 
                        projects.length < 1 ?
                        <Button 
                            onClick={(e) => handleSubmit(e)}
                            size="small" variant="contained"
                            style={{ backgroundColor: "#FAC710", boxShadow: "none", color: 'black', borderRadius: 0, borderStyle: "outset", padding:10}}>
                            <AddIcon />
                            NEW PROJECT
                            </Button>
                        :
                        <Button
                        disabled={true}
                        onClick={(e) => handleSubmit(e)}
                        size="small" variant="contained"
                        style={{ backgroundColor: "grey", boxShadow: "none", color: 'white', borderRadius: 0, borderStyle: "outset", padding:10}}>
                        UPGRADE FOR MORE PROJECTS
                        </Button>
                        :<Button
                            onClick={(e) => handleSubmit(e)}
                            size="small" variant="contained"
                            style={{ backgroundColor: "#FAC710", boxShadow: "none", color: 'black', borderRadius: 0, borderStyle: "outset", padding:10}}>
                            <AddIcon />
                            NEW PROJECT
                            </Button> : ''
                            }
                            </Box>
                    {/* </Box> */}
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ marginLeft: 10, marginBottom: 5, flexGrow: 1 }} noValidate autoComplete="off">


                        {/* <OutlinedInput label="" placeholder="Project name" size="small" name="name" />
                    
                        <button style={{marginLeft: 0}} size="small" variant="contained" type="submit">Create new project</button> */}
                        <Suspense fallback={<div>Loading... </div>}>
                            {loading ? <Loader color="black" /> : projects && objectsAreLoaded && <ProjectsTable isGuest={false} objectsAreLoaded={objectsAreLoaded} projects={projects} />}
                        </Suspense>

                    </Box>
                </Grid>
                <Grid item xs={12} md={9} sx={{ mt: 0, mb: 4 }}>
                <Box sx={{ marginLeft: 10, flexGrow: 1 }}>
                    {/* <Box sx={{ marginLeft: 10, marginRight: 10, marginBottom: 3, flexGrow: 1, display: "flex", justifyContent: "space-between" }} > */}
                        {/* <Box sx={{ display: 'flex' }}>  */}
                            <Typography component="h1" variant="h4" fontWeight={'700'}>
                                Creations by others
                            </Typography>
                        {/* </Box> */}
                        </Box>
                        </Grid>
                        <Grid item xs={12}>
                    <Box sx={{ marginLeft: 10, marginBottom: 10, flexGrow: 1 }} noValidate autoComplete="off">

                    <Suspense fallback={<div>Loading... </div>}>
                            {guestProjects && objectsAreLoaded && <ProjectsTable isGuest={true} objectsAreLoaded={objectsAreLoaded} projects={guestProjects} />}
                        </Suspense>

                    </Box>
                </Grid>


            </Grid>
        </Suspense>

        </div>
    )
};


export default ProjectsPage;
