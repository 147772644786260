import React, {useEffect, useState} from "react";
import { Grid } from "@mui/material/";
import AppNavBar from "../components/AppNavBar";
import { MiroShareButton, GoogleShareButton } from "../components/ShareModal";
import { useAccountStore } from "../zustand/account"; 
import { deleteUserAndSubs, getUserAccount, getMiroSyncEntry, getGoogleSyncEntry, deleteUserMiroEntry, deleteUserGoogleEntry, getAuth0URL, getStripeCheckoutURL } from "../api"; 
import Container from '@mui/material/Container';

import { LoadingOverlay, Card, Stack, Text, Group, Badge, Button, ActionIcon } from '@mantine/core';

 
const TemplatesPage = () => {

    const GridBackground = () => {
        return (
            <div style={{ position: "absolute", left: 0, top: 0, bottom: 0, zIndex: -1, width: "100%", height: "100%", backgroundImage: 'url("/image/background-grid.png")', backgroundAttachment: "fixed" }}></div>

        )
    }

    const { logUserOut, isAuthUser, userStatus, checkAuthTokenValidSubscription} = useAccountStore();

     
    const [haveEntry, setHaveEntry] = useState(false)
    const [haveGoogleEntry, setHaveGoogleEntry] = useState(false)
    const [loading, setLoading] = useState(true)
    const [createdAt, setCreatedAt] = useState()
    const [lastLoginDate, setLastLoginDate] = useState()
    const [userName, setUserName] = useState()
    const [userEmail, setUserEmail] = useState()


    return (
        <>  <AppNavBar pageName={"Account Settings"} />
         <Container component="main" maxWidth="lg">
        <GridBackground />
            <Grid container> 
             { userStatus === "loggedIn-active" ?             
            <Grid item xs={12} mt={2.5}>
              <Text fw={700} size={'lg'}>
                Copy the below templates to use them!
              </Text>
              <Text fw={500} size={'sm'}>
                Miro Co-design template <a target="_blank" href="https://miro.com/app/board/uXjVMiH4pIk=/?share_link_id=13824265482">here</a>.
              </Text>
              <Text fw={500} size={'sm'}>
                Google Slides pitch template <a target="_blank" href="https://docs.google.com/presentation/d/1cR0YpP11YFtBUXnLc39R0bDYK7BOl89LVAqD2HVKBeM/edit?usp=sharing">here</a>.
              </Text>
            </Grid>
            : 
            <Grid item xs={12} mt={2.5}>
              <Text fw={500} size={'sm'}>
            Upgrade for Miro/Google Slides templates.
              </Text>
            </Grid>}
            </Grid>
            </Container></>
    )
};


export default TemplatesPage;
