import React, { useState } from 'react';
import { useObjectStore } from "../zustand/objects";
import { Grid, Modal, Box, Typography } from "@mui/material/";
import { LoadingOverlay, Card, Button, Text, Group, ActionIcon, Textarea } from '@mantine/core';
import shortid from "shortid";


export default function TextModal() {

  const { 
    addTextObject,
    currentObjectArtboard,
    textModalOpen,
    switchTextModalOpen,
    arrangeUndoRedo
  } = useObjectStore();

  const [textOpen, setTextOpen] = useState(false);
  const [textValue, setTextValue] = useState('')

  const onTextSubmit = () => { 
    if (textValue) {
      const id = shortid.generate()
       // send update to zustand first (so we have state to grab for undo array)
       addTextObject({ id: id, currentObjectArtboard, textKind: "plain text", content: textValue, matrix: null, undo: false })
       // send current state to undoActions
    arrangeUndoRedo({
      updateType: "objectAdd", 
      artboard: currentObjectArtboard, 
      objectID: id,
      undo:false
      })
      switchTextModalOpen()
    }
  }

  const onTextCancel = () => {
    switchTextModalOpen()
  }

  const handleChange = (e) => {
    setTextValue(e.target.value)
  }

  return (
    <Modal
      open={textModalOpen}
      onClose={onTextCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card style={{borderRadius:32, width: 500, padding:0,
        height: 180,  top: '50%', overflow:'hidden',
        left: '50%',position: 'absolute',transform: 'translate(-50%, -50%)'}}>
        <Grid container style={{padding:20}}>
          <Grid item xs={12}>
          <Text size="xl" fw={700}>
          Enter scene text
        </Text>
        </Grid>
        <Grid item xs={12}>
          
        <Textarea
          onChange={handleChange}
        />
        </Grid>
        <Grid item xs={12}>
        <Group>
        <Button 
          style={{
             paddingTop: 1, paddingBottom: 1, marginTop: 15, inline: true, fontSize: 12, backgroundColor: 'black', borderRadius: 32
          }} onClick={() => onTextCancel()}>Cancel</Button>
        <Button 
          style={{
             paddingTop: 1, paddingBottom: 1, marginTop: 15, inline: true, fontSize: 12, backgroundColor: 'black', borderRadius: 32
          }} onClick={() => onTextSubmit()}>Submit</Button>
          </Group>
        </Grid>
        </Grid>
      </Card>
    </Modal>
  );
}