import React, { Suspense } from "react";
import { Text, Plane } from "@react-three/drei";
import { useObjectStore } from "../zustand/objects";
import * as THREE from "three";
import { useThree } from "@react-three/fiber";

const PreviewText = ({ content, position, rotation, scale, destination, matrixState, colour, bGColour }) => {

  const { updateArtboard, updateArtboardAndPreviewCamera, artboards, shapeHoverChange, objectsAreLoaded } = useObjectStore();
  const { camera } = useThree()

  const passdownFunctionPreview = () => {
    destination && shapeHoverChange(false)
    destination && updateArtboard(destination)
    // destination && Promise.resolve(updateArtboardAndPreviewCamera(destination))
    // .then(camera.position.set(
    //   artboards[destination].find((x) => x.id === "camera").position[0],
    //   1.75, 
    //   artboards[destination].find((x) => x.id === "camera").position[2]))
  }

  const hoverShape = (value) => {
    shapeHoverChange(value);
  }

  return (
    <><Suspense fallback={null}>
      <group
        scale={objectsAreLoaded && new THREE.Vector3().setFromMatrixScale(matrixState)}
        position={new THREE.Vector3().setFromMatrixPosition(matrixState)}
          rotation={new THREE.Euler().setFromRotationMatrix(matrixState)}>
      {/* <Text 
         scale={[2.5, 2.5, 2.5]}
         position={[0,0.125,0]}
        color="black" // default
        anchorX="center" // default
        anchorY="middle" // default
        overflowWrap="break-word"
        maxWidth={1}
        onPointerOver={() => destination && hoverShape(true)}
        onPointerOut={() => destination && hoverShape(false)}
        onClick={() => destination && passdownFunctionPreview()}>
      {content}
      </Text> */}
        <Text
            // scale={[2.5, 2.5, 2.5]}
            fontSize={0.1}
            scale={[1,1,1]}
            position={[0, 0.125, 0]}
            anchorX={0.5} // default
            anchorY={-0.35} // default
            overflowWrap="break-word"
            maxWidth={1}
            clipRect={[-0.5,-0.595,1,1]}
            wrap={true}
            color={colour}
          onPointerOver={() => destination && hoverShape(true)}
          onPointerOut={() => destination && hoverShape(false)}
          onClick={() => destination && passdownFunctionPreview()}
        >
          {content}
        </Text>
        {bGColour !== " " && <Plane
          // onClick={clickedShape} 
          onPointerOver={() => destination && hoverShape(true)}
          onPointerOut={() => destination && hoverShape(false)}
          onClick={() => destination && passdownFunctionPreview()}
          smoothness={4} scale={[1, 1, 1]} position={[0, 0, -0.01]}>
          <meshPhongMaterial
            receiveShadow
            attach="material"
            opacity={1}
            color={bGColour}
            side={THREE.DoubleSide}
          />
        </Plane>}
      </group>
      </Suspense>
    </>
  );
};

export default PreviewText;
