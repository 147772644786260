import React, { useEffect } from "react";
import { useStore } from 'zustand';
import { useObjectStore } from "../zustand/objects";
import {Box} from "@mui/material/";
import UndoIcon from '@mui/icons-material/Undo';
import { ActionIcon } from '@mantine/core';

const UndoButton = () => {
  const {
    sendUpdateToChannel,
    arrangeUndoRedo, 
    undoActions,
    redoActions
  } = useObjectStore();

  // const useTemporalStore = (selector, equality) => 
  //   useStore(useObjectStore.temporal, selector, equality);

  // const { undo, redo, clear, pastStates, futureStates } = useTemporalStore(
  //   (state) => state,
  // );
  


  // useEffect(() => {
  //   console.log(pastStates)
  // },[pastStates])

  const asyncUndo = async (data) => {
    arrangeUndoRedo(data)
  }
  
  const handleUndoClick = () => {
    const data = undoActions[0]
    asyncUndo(data).then(() => 
    sendUpdateToChannel(data))
    
  }

  const asyncRedo = async (data) => {
    arrangeUndoRedo(data)
   }


  const handleRedoClick = () => {
    const data = redoActions[0]
    asyncRedo(data).then(() => 
    sendUpdateToChannel(data))
  }

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.repeat) { return }  
      if (event.key === 'z' && (event.ctrlKey || event.metaKey) && !event.shiftKey) {
        undoActions.length > 0 && handleUndoClick()
      }
      if (event.key === 'z' && (event.ctrlKey || event.metaKey) && event.shiftKey) {
        redoActions.length > 0 && handleRedoClick()
      }
    }
    document.addEventListener("keydown", handleKeyDown)
  
    return () => document.removeEventListener("keydown", handleKeyDown)
  }, [undoActions.length, redoActions.length])
  
  return (
    <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      height: "100%",
      justifyContent: 'flex-start',
      margin: "2.5px",
      paddingBottom: 0.5
    }}
    >
      <ActionIcon disabled={undoActions.length <= 0}
      size={'sm'}
        onClick={() => handleUndoClick()}
        // onClick={() => undoRemake()}
        variant="transparent"
        color="#424242"
        bg={'none'}
      >
        <UndoIcon  fontSize={'small'} />
      </ActionIcon>
  {/* <button style={{backgroundColor: "none"}} disabled={undoActions.length <= 0} size={"small"} onClick={() => undoRemake()}>undo</button> */}
  </Box>
  );
};

export default UndoButton;
