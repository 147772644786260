/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useTexture, Box } from '@react-three/drei'
import * as THREE from "three";


export function StackedSidebar({ ...props }) {

  const material = new THREE.MeshBasicMaterial({
    color: props.colour,
    transparent: false, opacity: 1
  });

  const group = useRef()



  return (
      <><group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
          props.clickFunction()}}>
          <Box position={[0,0.85,0]} args={[0.5, 0.25, 0.0125]} radius={0.05} smoothness={4} >
              <meshPhongMaterial
                  receiveShadow
                  color={props.colour}
                  attach="material"
                  opacity={1}

              />
          </Box>
      </group>
       <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
        props.clickFunction()}}>
        <Box position={[0,0.5,0]} args={[0.5, 0.25, 0.0125]} radius={0.05} smoothness={4} >
            <meshPhongMaterial
                receiveShadow
                color={props.colour}
                attach="material"
                opacity={1}

            />
        </Box>
    </group>
     <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
      props.clickFunction()}}>
      <Box position={[0,0.15,0]} args={[0.5, 0.25, 0.0125]} radius={0.05} smoothness={4} >
          <meshPhongMaterial
              receiveShadow
              color={props.colour}
              attach="material"
              opacity={1}
                          
          />
      </Box>
  </group></>
  )
}


export function StackedSidebarTexture({ ...props }) {

  const material = new THREE.MeshBasicMaterial({
    color: props.colour,
    transparent: false, opacity: 1
  });

  const group = useRef()
  const texture = useTexture(props.imageTexture)


  return (
      <><group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
          props.clickFunction()}}>
          <Box position={[0,0.85,0]} args={[0.5, 0.25, 0.0125]} radius={0.05} smoothness={4} >
              <meshPhongMaterial
                  receiveShadow
                  color={props.colour}
                  attach="material"
                  opacity={1} transparent
                  map={texture}  
              />
          </Box>
      </group>
       <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
        props.clickFunction()}}>
        <Box position={[0,0.5,0]} args={[0.5, 0.25, 0.0125]} radius={0.05} smoothness={4} >
            <meshPhongMaterial
                receiveShadow
                color={props.colour}
                attach="material"
                opacity={1} transparent
                map={texture}  
            />
        </Box>
    </group>
     <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
      props.clickFunction()}}>
      <Box position={[0,0.15,0]} args={[0.5, 0.25, 0.0125]} radius={0.05} smoothness={4} >
          <meshPhongMaterial
              receiveShadow
              color={props.colour}
              attach="material"
              opacity={1} transparent
              map={texture}  
          />
      </Box>
  </group></>
  )
}
