import React, { useEffect, Suspense, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button } from "@mui/material/";
import { Link } from "react-router-dom";

import PreviewViewport from "../components/PreviewViewport";
import ThirdPersonPreviewViewport from "../components/ThirdPersonPreviewViewport";
import { useObjectStore } from "../zustand/objects";
import { getSpecificUserProjectGuestAccessAPI, getModelURLs } from "../api";
import SwitchVRMode from "../components/SwitchVRMode";
import ChangePreviewArtboard from "../components/ChangePreviewArtboard";

import { useGLTF } from '@react-three/drei'
 
import { Grid } from "@mui/material/";

import * as THREE from "three";
import debounce from 'lodash.debounce';

function useQuery() { 
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const SharePreviewPage = ({ match }) => {

  let query = useQuery();

  const queryString = query.get("artboard")


  const { removePresence, currentObjectArtboard, presence, uniqueRealtimeId, addOrUpdatePresence, joinChannel, xanoClient, realtimeEventUpdate, updateArtboard, artboards, unloadObjects, getSpecificUserProject, getSpecificUserProjectGuest, loadObjects, previewVR, updateArtboardAndPreviewCamera, objectsAreLoaded, floorColour } = useObjectStore();
  
  const { projectKey } = useParams();

  
  const [modelURLs, setModelURLs] = useState()

  const prepareModelURLs = () => {
    getModelURLs(projectKey).then(e => setModelURLs(e.model_urls)).then(() => {
      modelURLs.map((val) => {
        useGLTF.preload(`https://x1hz-knoc-qcpv.e2.xano.io${val.modelPath}`)
      })
    })
  }
 
 
  async function unloadProject() {
    unloadObjects() 
  }

  const [guest, setGuest] = useState(false)

useEffect(() => {   
  unloadProject()
  .then(res =>getSpecificUserProjectGuestAccessAPI(projectKey))
  .then((response) => (response === true) ? 
  getSpecificUserProjectGuest(projectKey).then(res => setGuest(true))
  .then(res =>
    queryString ? updateArtboard(queryString) : updateArtboard('1'))
    .then(result => loadObjects())

  :
  getSpecificUserProject(projectKey)
  .then(res =>
    queryString ? updateArtboard(queryString) : updateArtboard('1'))
    .then(result => loadObjects()))
    // .then(() => prepareModelURLs())
    
}, []); // <-- empty dependency array

// XANO REALTIME SECTION
const [realtime, setRealtime] = useState(false)

const joinFunction = (action) => {
  setRealtime(true) 
  joinChannel({projectKey: projectKey, preview: {
      rotation: new THREE.Euler(), 
      position: new THREE.Vector3(), 
      mouse: new THREE.Vector2(), 
      page: 'preview', 
      device: 'computer', 
      artboard: currentObjectArtboard, 
      name: localStorage.getItem('firstName') 
    }})
}
 
try {
  // declare channel
  const projectChannel = xanoClient.channel(`project/${projectKey}`, {presence:  true});
  // check for connection
  projectChannel.on("connection_status", (action) => {
    action.payload.status === "connected" && joinFunction(action)
    // Send a message to only other authenticated users in a channel
  })
   // react to someone leaving
   projectChannel.on("presence_update", (action) => {
    debounce(removePresence(action.payload.presence.socketId), 100)
  })

  const users = projectChannel.getPresence();
  
  // listen for messages
  realtime && projectChannel.on("message", (action) => {
    if (action.options.type === "projectUpdate") {debounce(realtimeEventUpdate(action), 100)}
    else if (action.options.type === "presenceUpdate") {
      if (uniqueRealtimeId !== action.payload.uniqueRealtimeId) {
      debounce(addOrUpdatePresence(action.payload), 20)
    }
    }
  
  })

} catch (err) {
  console.log(err)
}

// END XANO REALTIME SECTION

 
  return (<div> 
    <Suspense fallback={<div>Loading... </div>}>
    {previewVR ?
      <>
      {/* <Suspense fallback={<div>Loading... </div>}> */}
      {objectsAreLoaded && <PreviewViewport guestMode={guest} floorColour={floorColour} projectKey={projectKey} artboard={currentObjectArtboard} artboards={artboards}  />}
        <Grid container spacing={2}>
          <Grid item md={8}></Grid>
          <Grid item xs={12} md={2}>
          <Button variant="contained" component={Link} to={`/story/${projectKey}`} style={{ height: "100%", backgroundColor: "white", boxShadow: "none", color: 'black', borderColor: 'black' }}>View Storyboard</Button>
          </Grid>
          <Grid item xs={12} md={2}>
            <SwitchVRMode />
          </Grid>
        </Grid>
        {/* </Suspense> */}
        </>
      :
      <>
      {objectsAreLoaded && <ThirdPersonPreviewViewport artboards={artboards} guestMode={guest} floorColour={floorColour} projectKey={projectKey} currentObjectArtboard={currentObjectArtboard} />}
        <Grid container spacing={2}>
          <Grid item xs={10}><ChangePreviewArtboard /></Grid>
          <Grid item xs={2}>
            <SwitchVRMode />
          </Grid>
        </Grid></>
    }
    </Suspense>
  </div>
  )
};

export default SharePreviewPage;
