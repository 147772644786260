/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import * as THREE from "three";

  

export default function UpwardsFacingPanel({ ...props }) {

  const material = new THREE.MeshBasicMaterial({
    color: props.colour,
    transparent: false, opacity: 1
});

    const group = useRef()
  const { nodes, materials } = useGLTF('/model/interface/ui/upwardsFacingPanel-transformed.glb')
  return (
     <group ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
      props.clickFunction()}}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <mesh castShadow receiveShadow geometry={nodes.Mesh_Mesh_head_geo001_lambert2SG001.geometry} material={material} />
      </group>
    </group>
  )  
  }
  
  useGLTF.preload('/model/interface/ui/upwardsFacingPanel-transformed.glb')
  