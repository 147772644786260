import React, { useState, useEffect } from "react";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useObjectStore } from "../zustand/objects";
import {Box} from "@mui/material/";
import { Button, Menu, Group, ActionIcon, rem, useMantineTheme } from '@mantine/core';

import {TemplateModal} from "./TemplateModal";


import ModelPoses from '../ModelPoses';

export const TemplateDropdown = ({projectKey}) => {

const [category, setCategory] = useState(null)
const [open, setOpen] = useState(false)
const [hover, setHover] = useState(false)

const handleSelect = (data) => {
    setCategory(data.value)
    setOpen(true)
}


  const categories = [
    { value: 'Construction', label: 'Add Construction template' },
    { value: 'Education', label: 'Add Education template' },
    { value: 'Home', label: 'Add Home template' },
    { value: 'Medical', label: 'Add Medical template' },
    { value: 'Menu', label: 'Add Menu template' },
    { value: 'Office', label: 'Add Office template' },
  ]

  const closeModal = () => {
    setOpen(false)
  }

  return (
<>
<Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      height: "100%",
      justifyContent: 'flex-start',
      margin: "2.5px",
      paddingBottom: 0.5
    }}
    >
    <Menu transitionProps={{ transition: 'pop' }}  withinPortal trigger="hover">
    <Menu.Target>
    <ActionIcon
            variant="transparent"
            color={!hover ? "#424242" : "#868e96"}
        onPointerOver={() => setHover(true)}
        onPointerLeave={() => setHover(false)}
            size={'xs'}
            mr={6}
          >
      <DashboardIcon
        variant="transparent"
        color={'black'}
        fontSize={'small'}
      />
      </ActionIcon>
    </Menu.Target>
    <Menu.Dropdown>
    {categories.map((mapped, i) => (
      <Menu.Item key={i} value={mapped.value} onClick={() => handleSelect(mapped)}>{mapped.label}</Menu.Item>
    ))}
    </Menu.Dropdown>
  </Menu>
  </Box>
<TemplateModal projectKey={projectKey} category={category} open={open} close={closeModal}/>
</>
  );
};





