import React, { useRef, useEffect, useState, Suspense, useCallback } from "react";
import { TransformControls, PivotControls, Plane } from "@react-three/drei";
import { useXR, useXREvent } from "@react-three/xr";
import { Text } from "@react-three/drei"; 
import { useObjectStore } from "../zustand/objects";
import { useThree } from "react-three-fiber";
import * as THREE from "three";

import debounce from 'lodash.debounce';


import VRControls from "./VRControls";

export const TextObject = ({ content, orbitControls, id, currentObjectArtboard, objectsAreLoaded, colour, bGColour, isLocked, matrixState }) => {

  const {
    updateObjectSelected,
    selectedObjectID,
    transformMode,
    updateObjectMatrix,
    transformModeChange,
    setIsDraggingModel,
    isDraggingModel,
    projectKey,
    arrangeUndoRedo
  } = useObjectStore();

  const worldPosition = new THREE.Vector3();
  const worldRotation = new THREE.Quaternion();
  const worldScale = new THREE.Vector3();
  const transformControls = useRef();
  const transformChild = useRef();
  const textRef = useRef(); 

  const clickedShape = () => {
    // !isLocked && !isDraggingModel && updateObjectSelected(id)
    if (isDraggingModel === false) {
      !isLocked ? updateObjectSelected(id) : updateObjectSelected('0')  }
  };

// useEffect(() => { 
// // console.log(transformChild.current)
//  if (transformMode === "scale") { if (!isLocked) {
//    if (transformControls.current) {
//      const controls = transformControls.current;
//      const callback = (event) => {
//        orbitControls.current.enabled = !event.value;
//        if (controls.dragging === false) {
//          ObjectScaleUpdate()

//        }
//      };
//      controls.addEventListener("dragging-changed", callback);
//      return () => controls.removeEventListener("dragging-changed", callback);
//    }
//  }}
// });


 // declare empty position, rotation and scale for .decompose()
 const pos =  new THREE.Vector3()
 const rot = new THREE.Quaternion()
 const sca = new THREE.Vector3()
 
 // get local copy of matrixState
 const newMatrix = new THREE.Matrix4().copy(matrixState)
 
 // decompose newMatrix and apply to pos, rot and sca
 newMatrix.decompose(pos, rot, sca)
 
 // create matrix for gizmo that doesn't include scale, which is applied to the group instead
 const gizmoMatrix = new THREE.Matrix4().compose(pos, rot, new THREE.Vector3(1,1,1))
// the below stops the object snapping back to old position while moving
const [gizmoMatrixx, setGizmoMatrixx] = useState(gizmoMatrix)

// useEffect(() => {
//   newMatrix.copy(matrixState).decompose(pos, rot, sca)
//   const gizmoMatrix = new THREE.Matrix4().compose(pos, rot, new THREE.Vector3(1, 1, 1))
//   setGizmoMatrixx(gizmoMatrix)
// }, [matrixState]);



const [interimMatrix, setInterimMatrix] = useState(matrixState)

const startDragging = () => {
  setIsDraggingModel(true)
}

const stopDragging = () => {
  setIsDraggingModel(false)
}

const handleDragging = useCallback(
  debounce(
    (e) =>
    selectedObjectID === id && setInterimMatrix(e)
  ,30))



const ObjectUpdate = (e) => {
  const pos2 = new THREE.Vector3()
  const rot2 = new THREE.Quaternion()
  const sca2 = new THREE.Vector3()
  const toDecompose = new THREE.Matrix4().copy(e)
  toDecompose.decompose(pos2, rot2, sca2)
   // send current state to undoActions
   arrangeUndoRedo({
    updateType: "objectUpdate", 
    artboard: currentObjectArtboard, 
    objectID: id,
    undo:false, 
    projectKey: projectKey, 
    field: "matrixState",
    })
    // send update to zustand
  updateObjectMatrix({
    id,
    currentObjectArtboard,
    matrix: e,
    undo: false
    // matrix: new THREE.Matrix4().compose(pos2, rot2, sca2),
    // matrix: new THREE.Matrix4().copy(e),
  })
  // setStateGrabbed(new THREE.Matrix4().copy(e))
  // before changed scale to make sure gizmo is right but not skewed in scale,
  // but now we're carrying the gizmo's matrix anyway, can just use e as is
  // setGizmoMatrixx(e)
  // setGizmoMatrixx(e)
  // setGizmoMatrixx(new THREE.Matrix4().compose(pos2, rot2, new THREE.Vector3(1, 1, 1)))

  
  // const toDecompose = new THREE.Matrix4().copy(transformChild.current.matrixWorld)
  
  // setGizmoMatrixx(new THREE.Matrix4().compose(pos2, rot2, new THREE.Vector3(1, 1, 1)))
}




 const missedShape = () => {
  !isDraggingModel && updateObjectSelected('0')
  transformModeChange({
    transformMode: "translate"
  })
};


    
const grabcontrols = useRef()
    
async function updatePositionVR() {
   // send current state to undoActions
   arrangeUndoRedo({
    updateType: "objectUpdate", 
    artboard: currentObjectArtboard, 
    objectID: id,
    undo:false, 
    projectKey: projectKey, 
    field: "matrixState",
    })
    // send update to zustand
  updateObjectMatrix({
    id,
    currentObjectArtboard,
    matrix: new THREE.Matrix4().copy(grabcontrols.current.matrixWorld),
    undo: false
  })
}


const [vrHovered, setVrHovered] = useState(false)
const [vrSelected, setVrSelected] = useState(false)
const [vrDragging, setVrDragging] = useState(false)

const xr = useXR()

const handleVRSelectEnd = () => {
if (xr.isPresenting) {
     if (vrSelected){
     updatePositionVR().then(() => grabcontrols.current.position.set(
       0,0,0
     )).then(() => grabcontrols.current.rotation.set(
      0,0,0
    )).then(() => setVrSelected(false))
}
  }
}

useXREvent('selectend', () => handleVRSelectEnd())



const handleDrag = useCallback(
  debounce(
    (e) =>
    selectedObjectID === id ? ObjectUpdate(e) : ''
  , 60)
  )



  if (!objectsAreLoaded) {
    return <div>Loading...</div>;
  } else {
    return ( 
      selectedObjectID === id ? !isLocked ? transformMode === "translate" ?
      <group onPointerMissed={(e) => {
          // e.stopPropagation()
          missedShape()
        }}>
      <PivotControls
      depthTest={false}
      lineWidth={5}
      matrix={!isDraggingModel && new THREE.Matrix4().copy(matrixState)}
      active={selectedObjectID === id ? [true, true, true ] : [false, false, false]}

      onDragStart={() => startDragging()}
      onDragEnd={() => {
        ObjectUpdate(interimMatrix)
        stopDragging()}}
      onDrag={(e) => handleDragging(e)}

      ref={transformControls}
      visible={selectedObjectID === id ? true : false}
      fixed={true}
      scale={150}
      >
        <group ref={transformChild}>
        <Suspense fallback={null}>
        <Text
            // scale={[2.5, 2.5, 2.5]}
            fontSize={0.1}
            scale={[1,1,1]}
            position={[0, 0.125, 0]}
            anchorX={0.5} // default
            anchorY={-0.35} // default
            overflowWrap="break-word"
            maxWidth={1}
            clipRect={[-0.5,-0.595,1,1]}
            wrap={true}
            onClick={clickedShape}
            color={colour}
          >
          {content}
        </Text>
        {bGColour !== " " && <Plane 
          onClick={clickedShape} smoothness={4} scale={[1,1,1]} position={[0,0,-0.01]}>
              <meshPhongMaterial
                receiveShadow
                attach="material"
                opacity={1} 
                color={bGColour}
                side={THREE.DoubleSide}
              />
            </Plane>}
        </Suspense>
        </group>
      </PivotControls></group>
      :
      <group onPointerMissed={() => {
        missedShape()
      }}>
        <TransformControls
        position={objectsAreLoaded && new THREE.Vector3().setFromMatrixPosition(matrixState)}
        // rotation={objectsAreLoaded && new THREE.Euler().setFromRotationMatrix(matrixState)}
        rotation={objectsAreLoaded && new THREE.Euler().setFromQuaternion(rot)}
        scale={objectsAreLoaded && new THREE.Vector3().setFromMatrixScale(matrixState)}
        showY={false}
        showX={false}
        showZ={false}
        // showX={selectedObjectID === id && (transformMode === "translate" || transformMode === "scale") ? true : (category === "shape") ? selectedObjectID === id && true : false}
        // showZ={selectedObjectID === id && (transformMode === "translate" || transformMode === "scale") ? true : false}
        translationSnap={0.125}
        // rotationSnap={0.31416}
        scaleSnap={0.025}
        ref={transformControls}
        mode={'scale'}  
      >
        <group ref={transformChild}>
        <Suspense fallback={null}>
        <Text
            // scale={[2.5, 2.5, 2.5]}
            fontSize={0.1}
            scale={[1,1,1]}
            position={[0, 0.125, 0]}
            anchorX={0.5} // default
            anchorY={-0.35} // default
            overflowWrap="break-word"
            maxWidth={1}
            clipRect={[-0.5,-0.595,1,1]}
            wrap={true}
            onClick={clickedShape}
            color={colour}
          >
          {content}
        </Text>
        {bGColour !== " " && <Plane 
          onClick={clickedShape} smoothness={4} scale={[1,1,1]} position={[0,0,-0.01]}>
              <meshPhongMaterial
                receiveShadow
                attach="material"
                opacity={1} 
                color={bGColour}
                side={THREE.DoubleSide}
              />
            </Plane>}
        </Suspense>
        </group>
        </TransformControls></group>
      :
      <group onPointerMissed={() => {
        missedShape()
      }}>
        <TransformControls
      // position={objectsAreLoaded && new THREE.Vector3().setFromMatrixPosition(matrixState)}
      // rotation={objectsAreLoaded && new THREE.Euler().setFromRotationMatrix(matrixState)}
      // scale={objectsAreLoaded && new THREE.Vector3().setFromMatrixScale(matrixState)}
        showY={false}
        showX={false}
        showZ={false}
        translationSnap={0.125}
          // rotationSnap={0.31416}
          scaleSnap={0.025}
        ref={transformControls}
        mode={transformMode}
        matrixWorld={new THREE.Matrix4().copy(matrixState)}
      >
        <Text
            // scale={[2.5, 2.5, 2.5]}
            fontSize={0.1}
            scale={[1,1,1]}
            position={[0, 0.125, 0]}
            anchorX={0.5} // default
            anchorY={-0.35} // default
            overflowWrap="break-word"
            maxWidth={1}
            clipRect={[-0.5,-0.595,1,1]}
            wrap={true}
            onClick={clickedShape}
            color={colour}
          >
          {content}
        </Text>
        {bGColour !== " " && <Plane 
          onClick={clickedShape} smoothness={4} scale={[1,1,1]} position={[0,0,-0.01]}>
              <meshPhongMaterial
                receiveShadow
                attach="material"
                opacity={1} 
                color={bGColour}
                side={THREE.DoubleSide}
              />
            </Plane>}
      </TransformControls></group>
        :
        <VRControls 
          isLocked={isLocked}
          idprop={id}
          currentObjectArtboard={currentObjectArtboard}
          objectsAreLoaded={objectsAreLoaded}
          matrixState={new THREE.Matrix4().copy(matrixState)}
          // rot={rot}
          // scale={sca}
        >
          <Text
            // scale={[2.5, 2.5, 2.5]}
            fontSize={0.1}
            scale={[1,1,1]}
            position={[0, 0.125, 0]}
            anchorX={0.5} // default
            anchorY={-0.35} // default
            overflowWrap="break-word"
            maxWidth={1}
            clipRect={[-0.5,-0.595,1,1]}
            wrap={true}
            onClick={clickedShape}
            color={colour}
            ref={textRef}

          >
            {content}
          </Text>
         {bGColour !== " " && <Plane 
                onClick={clickedShape} smoothness={4} 
          scale={[1,1,1]} 
          position={[0,0,-0.01]}>
              <meshPhongMaterial
                receiveShadow
                attach="material"
                opacity={1} 
                color={bGColour}
                side={THREE.DoubleSide}
              />
            </Plane>}
        


        </VRControls>
    );
  }
};

export const GroupedTextObject = ({ content, orbitControls, undoing, position, rotation, scale, id, currentObjectArtboard, objectsAreLoaded, colour, isLocked, matrixState }) => {

  const {scene} = useThree();
  const modelRef = useRef()
  const {
    updateObjectSelected,
    transformModeChange
  } = useObjectStore();

  useEffect(() => {
      Promise.resolve(scene.getObjectByName(id).remove(modelRef.current))
.then(Promise.resolve(modelRef.current.applyMatrix4(matrixState)))
.then(Promise.resolve(scene.getObjectByName(id).attach(modelRef.current)))
},[]);


const missedShape = () => {
  updateObjectSelected('0')
  transformModeChange({
    transformMode: "translate"
  })
};

  if (!objectsAreLoaded) {
    return <div>Loading...</div>;
  } else {
    return ( 
      
      <group
      ref={modelRef}
      scale={objectsAreLoaded && new THREE.Vector3().setFromMatrixScale(matrixState)}
      onPointerMissed={() => {
        missedShape()
      }}
      >
        <Suspense fallback={null}>
          <group>
        <Text 
          scale={[2.5, 2.5, 2.5]}
          position={[0,0.125,0]}
          // color="black" // default
          anchorX="center" // default
          anchorY="middle" // default
          overflowWrap="break-word"
          maxWidth={1}
          wrap={true}
          color={colour}
          >
          {content}
            </Text>
           
          </group>
        </Suspense>
      </group>




    );
  }
};
