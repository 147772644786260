/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { Box, useTexture, Edges} from '@react-three/drei'
import * as THREE from "three";

  
  export function FlatPanels({ ...props }) {

  const material = new THREE.MeshBasicMaterial({
    color: props.colour,
    transparent: false, opacity: 1
});

    const group = useRef()

    return (
      <>
      <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
        props.clickFunction()}}>
        <Box position={[-1.1,0.5,0.35]} args={[1, 1, 0.05]} rotation={[0,0.6,0]} radius={0.05} smoothness={4} >
            <meshPhongMaterial
                receiveShadow
                color={props.colour}
                attach="material"
                opacity={1}
            />
            <Edges
    scale={1}
    threshold={15} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
    color="black"
  />
        </Box>
    </group>
      <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
        props.clickFunction()}}>
        <Box position={[0,0.5,0]} args={[1, 1, 0.05]} radius={0.05} smoothness={4} >
            <meshPhongMaterial
                receiveShadow
                color={props.colour}
                attach="material"
                opacity={1}
            />
            <Edges
    scale={1}
    threshold={15} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
    color="black"
  />
        </Box>
    </group>
      <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
        props.clickFunction()}}>
        <Box position={[1.1,0.5,0.35]} args={[1, 1, 0.05]} rotation={[0,-0.6,0]} radius={0.05} smoothness={4} >
            <meshPhongMaterial
                receiveShadow
                color={props.colour}
                attach="material"
                opacity={1}
            />
            <Edges
    scale={1}
    threshold={15} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
    color="black"
  />
        </Box>
    </group>
    </>
    )
  } 
  
 

  export function FlatPanelsTexture({ ...props }) {
    const texture = useTexture(props.imageTexture)
  
      const group = useRef()
  
      return (
        <>
        <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
          props.clickFunction()}}>
          <Box position={[-1.1,0.5,0.35]} args={[1, 1, 0.05]} rotation={[0,0.6,0]} radius={0.05} smoothness={4} >
              <meshPhongMaterial
                  receiveShadow
                  color={props.colour}
                  attach="material"
                  opacity={1} transparent
                  map={texture}
              />
              <Edges
    scale={1}
    threshold={15} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
    color="black"
  />
          </Box>
      </group>
        <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
          props.clickFunction()}}>
          <Box position={[0,0.5,0]} args={[1, 1, 0.05]} radius={0.05} smoothness={4} >
              <meshPhongMaterial
                  receiveShadow
                  color={props.colour}
                  attach="material"
                  opacity={1} transparent
                  map={texture}
              />
              <Edges
    scale={1}
    threshold={15} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
    color="black"
  />
          </Box>
      </group>
        <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
          props.clickFunction()}}>
          <Box position={[1.1,0.5,0.35]} args={[1, 1, 0.05]} rotation={[0,-0.6,0]} radius={0.05} smoothness={4} >
              <meshPhongMaterial
                  receiveShadow
                  color={props.colour}
                  attach="material"
                  opacity={1} transparent
                  map={texture}
              />
              <Edges
    scale={1}
    threshold={15} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
    color="black"
  />
          </Box>
      </group>
      </>
      )
    } 
    
  
  