
import React, { useRef, useEffect, useState } from 'react'
import { Image as DreiImage, Cylinder, useTexture, Plane} from '@react-three/drei'
import * as THREE from "three";

import {CurvedPanels, CurvedPanelsTexture} from "./CurvedPanels" 

export function AppleUI({ ...props }) {

    const group = useRef()
    const imageRef = useRef() 

    const imageSrc = `/image/apple-ui-elements/${props.pose.name}.png`

    const width = props.pose.name === "keyboard" ? 0.544 : props.pose.name ==="sidebar-and-table" ? 0.96 : props.pose.name ==="alert" ? 0.32 : props.pose.name ==="plain-table-with-index-bar" ? 0.508 : props.pose.name ==="toolbar" ? 0.511 : props.pose.name ==="tab-bar" ? 0.068 : props.pose.name ==="window" && 1.306
    
    const height = props.pose.name ==="keyboard" ? 0.328 : props.pose.name ==="sidebar-and-table" ? 0.88 : props.pose.name ==="alert" ? 0.279 : props.pose.name ==="plain-table-with-index-bar" ? 0.88 : props.pose.name ==="toolbar" ? 0.068 : props.pose.name ==="tab-bar" ? 0.328 : props.pose.name ==="window" && 0.77
    
    const texture = useTexture(imageSrc)

    
    return (
        <>
            
            {!props.curved ?
            <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {
                e.stopPropagation()
                props.clickFunction() 
            }}>
                <DoubleSidedImage imageSrc={imageSrc} transparent opacity={1} height={height} width={width} />
                </group>
                
               : <group onClick={(e) => {
                e.stopPropagation()
                props.clickFunction() 
            }}>
               <CurvedPanelsTexture clickFunction={props.clickFunction} colour={props.colour} imageTexture={imageSrc} curveAmount={props.curveAmount} width={width * 2} height={height * 2}/> 
               </group> }
            
        </>
    )
}

export function BackgroundUI({ ...props }) {

    const group = useRef()
    const imageRef = useRef() 

    const imageSrc = `/image/background-spaces/${props.pose.name}.png`

    const width = 2.992
    
    const height = 1.684
    
    const texture = useTexture(imageSrc)

    
    return (
        <>
            
            {!props.curved ?
            <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {
                e.stopPropagation()
                props.clickFunction() 
            }}>
                <DoubleSidedImage imageSrc={imageSrc} transparent opacity={1} height={height} width={width} />
                </group>
                
               : <group onClick={(e) => {
                e.stopPropagation()
                props.clickFunction() 
            }}>
               <CurvedPanelsTexture clickFunction={props.clickFunction} colour={props.colour} imageTexture={imageSrc} curveAmount={props.curveAmount} width={width * 2} height={height * 2}/> 
               </group> }
            
        </>
    )
}
   
export function MicrosoftUI({ ...props }) {

    const group = useRef()
    const imageRef = useRef() 

    const imageSrc = `/image/microsoft-ui-elements/${props.pose.name}.png`

    const width = props.pose.name === "backplate" ? 0.488 : props.pose.name ==="innerplate" ? 0.484 : props.pose.name ==="pressable-button" ? 0.408 : props.pose.name ==="near-menu" ? 0.524 : props.pose.name ==="dialog" ? 0.576 : props.pose.name ==="slider" ? 0.448 : props.pose.name ==="backplate-group" ? 0.834 : props.pose.name === "3x3-near-menu" && 0.425
    
    const height = props.pose.name ==="backplate" ? 0.379 : props.pose.name ==="innerplate" ? 0.349 : props.pose.name ==="pressable-button" ? 0.120 : props.pose.name ==="near-menu" ? 0.120 : props.pose.name ==="dialog" ? 0.290 : props.pose.name ==="slider" ? 0.096 : props.pose.name ==="backplate-group" ? 0.578 : props.pose.name === "3x3-near-menu" && 0.344
    
    return (
        <>
        {!props.curved ?
            <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {
                e.stopPropagation()
                props.clickFunction()
            }}>
                <DoubleSidedImage imageSrc={imageSrc} transparent opacity={1} height={height} width={width} /></group>
               :  
               <group onClick={(e) => {
                e.stopPropagation()
                props.clickFunction() 
            }}>
               <CurvedPanelsTexture clickFunction={props.clickFunction} colour={props.colour} imageTexture={imageSrc} curveAmount={props.curveAmount} width={width * 2} height={height * 2}/>
               </group>}
            
        </>
    )
}

   


export function FloatgridsUI({ ...props }) {

    const group = useRef()
    const imageRef = useRef() 

    const imageSrc = `/image/floatgrids-ui/${props.pose.name}.png`

    const width = props.pose.name === "accordion" ? 0.781 : props.pose.name ==="alert" ? 0.560 : props.pose.name ==="card" ? 0.243 : props.pose.name ==="checkbox" ? 0.352 : props.pose.name ==="dark-button" ? 0.214 : props.pose.name ==="dialogue" ? 0.450 : props.pose.name ==="horizontal-buttons" ? 0.868 : props.pose.name ==="icon-button" ? 0.102 : props.pose.name ==="keyboard" ? 0.684 : props.pose.name === "keypad" ? 0.204 : props.pose.name === "progress-bar" ? .260 : props.pose.name === "radio-button" ? 0.197 : props.pose.name === "spinner" ? 0.14 : props.pose.name === "tall-card" ? 0.243 : props.pose.name === "text-area" ? 0.309 : props.pose.name === "text-field" ? 0.309 : props.pose.name === "vertical-buttons" ? 0.106 : props.pose.name === "white-button" && 0.214
    
    const height = props.pose.name === "accordion" ? 0.081 : props.pose.name ==="alert" ? 0.056 : props.pose.name ==="card" ? 0.243 : props.pose.name ==="checkbox" ? 0.048 : props.pose.name ==="dark-button" ? 0.104 : props.pose.name ==="dialogue" ? 0.212 : props.pose.name ==="horizontal-buttons" ? 0.042 : props.pose.name ==="icon-button" ? 0.102 : props.pose.name ==="keyboard" ? 0.323 : props.pose.name === "keypad" ? 0.320 : props.pose.name === "progress-bar" ? .024 : props.pose.name === "radio-button" ? 0.024 : props.pose.name === "spinner" ? 0.14 : props.pose.name === "tall-card" ? 0.512 : props.pose.name === "text-area" ? 0.180 : props.pose.name === "text-field" ? 0.118 : props.pose.name === "vertical-buttons" ? 0.320 : props.pose.name === "white-button" && 0.104
    
    return (
        <>
        {!props.curved ?
            <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {
                e.stopPropagation()
                props.clickFunction()
            }}>
                <DoubleSidedImage imageSrc={imageSrc} transparent opacity={1} height={height} width={width} /></group>
               :
               <group onClick={(e) => {
                e.stopPropagation()
                props.clickFunction() 
            }}> 
               <CurvedPanelsTexture clickFunction={props.clickFunction} colour={props.colour} imageTexture={imageSrc} curveAmount={props.curveAmount} width={width * 2} height={height * 2}/>
               </group>}
            
        </>
    )
}

   
   




export function FloatgridsLayout({ ...props }) {

    const group = useRef()
    const imageRef = useRef() 

    const imageSrc = `/image/floatgrids-layout/${props.pose.name}.png`

    const width = props.pose.name ==="1-column-1-main" ? 1.024 : props.pose.name ==="2-column-1-main-action-bar" ? 1.384 : props.pose.name ==="2-column-1-main" ? 1.384 : props.pose.name ==="curved-columns-action-bar" ? 1.476 : props.pose.name ==="curved-main" ? 1.368 : props.pose.name ==="sample-2" ? 1.460 : props.pose.name ==="sample" ? 0.952 : props.pose.name === "single-action-bar-main-1" ? 0.960 : props.pose.name === "single-action-bar-main-2" ? 0.960: props.pose.name === "single-action-bar-main-3" && 0.960
    
    const height = props.pose.name ==="1-column-1-main" ? 0.657 : props.pose.name ==="2-column-1-main-action-bar" ? 0.560 : props.pose.name ==="2-column-1-main" ? 0.560 : props.pose.name ==="curved-columns-action-bar" ? 0.568 : props.pose.name ==="curved-main" ? 0.560 : props.pose.name ==="sample-2" ? 0.560 : props.pose.name ==="sample" ? 0.560 : props.pose.name === "single-action-bar-main-1" ? 0.655 : props.pose.name === "single-action-bar-main-2" ? 0.560: props.pose.name === "single-action-bar-main-3" && 0.560
    
    return (
        <> 
        {!props.curved ?
            <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {
                e.stopPropagation()
                props.clickFunction()
            }}>
                <DoubleSidedImage imageSrc={imageSrc} transparent opacity={1} height={height} width={width} /></group>
               :   <group onClick={(e) => {
                e.stopPropagation()
                props.clickFunction() 
            }}><CurvedPanelsTexture clickFunction={props.clickFunction} colour={props.colour} imageTexture={imageSrc} curveAmount={props.curveAmount} width={width * 2} height={height * 2}/> 
            </group>}
            
        </>
    )
}






// THE PLANE IN A NESTED OBJECT TO ALLOW USETEXTURE TO WORK

const DoubleSidedImage = ({imageSrc, height, width}) => {

    const imageRef = useRef()
  
    const texture = useTexture(imageSrc)
  
   return (
    <Plane ref={imageRef} position={[0, height, 0]} smoothness={4} scale={[width * 2, height * 2,1]}>
    <meshPhongMaterial
      receiveShadow
      attach="material"
      opacity={1} transparent
      map={texture}
      side={THREE.DoubleSide}
    />
  </Plane>)
  }
  
  
  