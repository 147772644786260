import React, { useRef } from "react";
import * as THREE from "three";
import { Box, Sphere, Edges } from '@react-three/drei'

import VRHeadset from "./ModelFiles/VRHeadset"

export default function PlayerFigure({ rotation, position, color, device }) {



    return (
        <group
            position={position && [position.x, position.y, position.z]}
            rotation={rotation && new THREE.Euler(rotation._x, rotation._y, rotation._z, 'XYZ')}
        >
                <group
                    position={[0, 0, 0]}
                    rotation={[0, Math.PI / 2, 0]}
                    scale={1.5}>
                    <VRHeadset colour={color} clickFunction={() => console.log("hey don't poke me!")}/>
                    <Sphere position={[-0.1,0.06,0]} scale={0.095} receiveShadow material-color={color} material={new THREE.MeshStandardMaterial}>
                    <Edges
                    scale={1}
                    threshold={20} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
                    color="grey"
                /></Sphere>
                    <Sphere position={[-0.1,-0.07,0]} scale={0.05} receiveShadow material-color={color} material={new THREE.MeshStandardMaterial}>
                    <Edges
                    scale={1}
                    threshold={20} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
                    color="grey"
                /></Sphere>
                </group>
            {/* } */}
        </group>
    )
}
