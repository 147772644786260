import React from 'react';
import { Canvas } from "@react-three/fiber";

import AIModel from "./AIModel";

export default function StagedAIModel({prompt}) {

    const color = "yellow"

    return (
        <Canvas shadows>
            {/* <Stage> */}
                {/* <Box/> */}
                <AIModel 
                prompt={prompt}
                />
            {/* </Stage> */}
        </Canvas>

    );
}