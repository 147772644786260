import React, { useEffect, useState, useMemo, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import {isMobile} from 'react-device-detect';

import Viewport from "../components/Viewport";
import LayerList from "../components/LayerList";
import OtherUser from "../components/OtherUser";

import { useObjectStore, bringToArtboard } from "../zustand/objects";
import { useAccountStore } from "../zustand/account";

import ArtboardPanel from "../components/ArtboardPanel";
import TopProjectAppBar from "../components/TopProjectAppBar";
import TextModal from "../components/TextModal";

import { Grid, Box } from "@mui/material/";
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Tooltip, AppShell, Divider, Tabs, Text, AppShellAside, AppShellSection, rem, LoadingOverlay } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DnsIcon from '@mui/icons-material/Dns';
import InterestsIcon from '@mui/icons-material/Interests';

import debounce from 'lodash.debounce';
import * as THREE from "three";

// MANTINE 

import { Group, Button,  Dialog } from '@mantine/core';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const DialogueDocs = () => {
  const {helpOpen, setHelpOpen} = useAccountStore()
  const [dialogueStatus, setDialogueStatus] = useState(1)

  const dialogueTitle = {
    1: '1/5: Project level ',
    2: '2/5: Content level',
    3: '3/5: Share and preview',
    4: '4/5: Configure content',
    5: '5/5: Collaborating'
  }

  const dialogueDescription = {
    1: 'Here you can change page, undo/redo, rename your project and trigger these instructions.',
    2: 'Add uploaded models, plain text, Figma frames, pre-made assets and templates here. Or generate with AI!',
    3: 'Here are options to preview your concept in first person, share preview links, configure and export storyboards.',
    4: 'Use this sidebar to browse and edit both scene objects and scenes. ',
    5: 'Here you can view who is online, bring them to your scene and observe what users can see in preview.'
  }
 
  const dialoguePosition = {
    1: {top:'6.5%', left:'1%'},
    2: {top:'6.5%', left:'40%'},
    3: {top:'6.5%', left:'71%'}, 
    4: {top:'30%', left:'54%'},
    5: {top:'75%', left:'54%'}
  }

  const resetStatus = () => {
    setDialogueStatus(1)
  }
  
  const closeClick = () => {
    setHelpOpen(false)
    setTimeout(resetStatus, 250);
  }

  return (
    <Dialog 
    position={dialoguePosition[dialogueStatus]}
    opened={helpOpen} withCloseButton onClose={() => closeClick()} size="lg" radius="md">
    {/* opened={helpOpen} withCloseButton onClose={() => handleClick(0)} size="lg" radius="md"> */}
      <Text size="sm" mb="xs" fw={500}>
        {dialogueTitle[dialogueStatus]}
      </Text>

      <Group align="flex-end">
      <Text size="xs" mb="xs" fw={400}>
        {dialogueDescription[dialogueStatus]}
      </Text>
        <Button color="black" disabled={dialogueStatus === 1} onClick={() => setDialogueStatus(dialogueStatus - 1) }>Back</Button>
        <Button color="black" disabled={dialogueStatus === 5} onClick={() => setDialogueStatus(dialogueStatus + 1)}>Next</Button>
      </Group>
    </Dialog>
  )
}


const EditorPage = () => {

  const renderCount = useRef(0);

  let query = useQuery();

  const queryString = query.get("artboard")

  const { projectKey } = useParams();
  
  const { removePresence, currentObjectArtboard, presence, uniqueRealtimeId, addOrUpdatePresence, joinChannel, xanoClient, realtimeEventUpdate, screenshotStatus, tabValue, setTabValue, getSpecificUserProject, artboards, loadObjects, updateArtboard, undoing, selectedObjectID, unloadObjects, getAIModels, isOtherPreviewOpen } = useObjectStore();

//XANO WEBSOCKETS

const [realtime, setRealtime] = useState(false)

const joinFunction = (action) => {
  setRealtime(true)
  joinChannel({projectKey: projectKey, cursor: {
      rotation: new THREE.Euler(), 
      position: new THREE.Vector3(), 
      mouse: new THREE.Vector2(), 
      page: 'editor',
      device: 'computer', 
      artboard: currentObjectArtboard, 
      name: localStorage.getItem('firstName')
    }})
}

try {
    // declare channel
    const projectChannel = xanoClient.channel(`project/${projectKey}`, {presence:  true});
    // check for connection
    projectChannel.on("connection_status", (action) => {
      action.payload.status === "connected" && joinFunction(action)
    })
    // react to someone leaving
    projectChannel.on("presence_update", (action) => {
      debounce(removePresence(action.payload.presence.socketId), 100)
    })
    // listen for messages
    realtime && projectChannel.on("message", (action) => {
      if (action.options.type === "projectUpdate") {debounce(realtimeEventUpdate(action), 100)}
      else if (action.options.type === "presenceUpdate") {
        if (uniqueRealtimeId !== action.payload.uniqueRealtimeId) {
        debounce(addOrUpdatePresence(action), 20)
      }
      }
    
    })

  } catch (err) {
    console.log(err)
  }

  const [modelURLs, setModelURLs] = useState()

  // const prepareModelURLs = () => {
  //   getModelURLs(projectKey).then(e => setModelURLs(e.model_urls)).then(() => {
  //     modelURLs && modelURLs.map((val) => {
  //       useGLTF.preload(`https://x1hz-knoc-qcpv.e2.xano.io${val.modelPath}`)
  //     })
  //   })
  // }  

  // have commented out unloadProject() and made getspecificUserProject() the async function
  // not 100% if this will cause issues. but seems to have stopped flickering of new proj. load.
  
  async function unloadProject() {
    unloadObjects()
  }

  useEffect(() => {
    
    unloadProject().then(() => 
    getSpecificUserProject(projectKey))
    .then(result => loadObjects())
    .then(res =>
      queryString ? updateArtboard(queryString) : updateArtboard('1'))
      // .then(() => prepareModelURLs())
       
  }, []);
  


  const [cameraDetails, setCameraDetails] = useState({
    id: "",
    position: []
  });




const [selectedTab, setSelectedTab] = useState('objects')

const handleTabClick = (e) => setSelectedTab(e)

const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);

const [mobileAsideOpened, { toggle: toggleMobileAside }] = useDisclosure();
const [desktopAsideOpened, { toggle: toggleDesktopAside }] = useDisclosure(true);

useEffect(() => {
  selectedObjectID !== '0' &&
  selectedTab === '' &&
  handleTabClick('objects')

}, [selectedObjectID]);

  return (
    <> 
    <AppShell
      header={{ height: 40 }}
      footer={{ height: 60 }}
      navbar={{ width: 250, breakpoint: 'sm', collapsed: { mobile: !mobileOpened, desktop: !desktopOpened } }}
      aside={{ width: 250, breakpoint: 'md', collapsed: { mobile: !mobileAsideOpened, desktop: !desktopAsideOpened } }}
      padding="md"

    >
      <LoadingOverlay loaderProps={{ children: <h5>Please wait</h5>, color: 'black' }} visible={screenshotStatus} zIndex={1200} overlayProps={{ radius: "sm", blur: 2 }} />
      <DialogueDocs/>
      <Box sx={{ flexGrow: 1, overflow: "hidden" }}
        >
        <TextModal />
        <Grid container style={{ overflow: "hidden" }}>
          <AppShell.Header bg={'#F9F9F9'} style={{border:'none'}}>
            <TopProjectAppBar mode={"editor"} projectKey={projectKey} />
          </AppShell.Header>
          <AppShell.Main p={0}>
          <Grid item xs={12}>
           <Viewport
              undoing={undoing}
              artboards={artboards} 
              projectKey={projectKey}
              presence={presence}
            />
          </Grid>
          </AppShell.Main>
         
         
        </Grid>
      </Box>
      <AppShellAside 
      // bg={"#F1F1F1"}
      p={0} style={{height:"100vh", gap:0, justifyContent:'space-between'}} 
      >
      <Tabs h={'70vh'} bg={'#F9F9F9'} value={tabValue} onChange={(val) => setTabValue(val)}  variant="outline"  pb={0} styles={{tab: {borderBottom:'none'}}}> 
      {/* <Tabs color="#424242" defaultValue="elements"  variant="outline" >  */}
      {/* <Tabs defaultValue="elements" color="#868e96"> */}
      <Tabs.List  >
        <Tabs.Tab fw={700} value="elements" color={'#F9F9F9'} 
        leftSection={<InterestsIcon style={{ width: rem(12), height: rem(12), borderBottom: '#F9F9F9'}} />}>
          <Text c={"#424242"} size={'xs'} fw={700} style={{borderBottom: '#F9F9F9'}}>ELEMENTS</Text>
        </Tabs.Tab>
        <Tabs.Tab fw={700} value="scenes" color={'#F9F9F9'}
        leftSection={<DnsIcon style={{ width: rem(12), height: rem(12) }} />}>
          <Text  c={"#424242"} size={'xs'} fw={700}>SCENES</Text>
        </Tabs.Tab>
      </Tabs.List>
      
      {/* MODEL CONTENT */}
      <Tabs.Panel value="elements" h={'70vh'} style={{borderTop:'none'}}>
      <AppShellSection p={5} pb={40} ml={0} mb={0}
      style={{ flexGrow:1, maxHeight:'70vh',
        overflow:"scroll"}} >
      <LayerList mobile={true}/>
      </AppShellSection>
      </Tabs.Panel>

      <div style={{ width: 15, position: "absolute", right: desktopAsideOpened ? 250 : 280, top: 20, zIndex: 500 }}>
              <Button onClick={toggleDesktopAside} variant="default" size="xs"
                style={{
                  height: 25, borderRadius: 30, paddingLeft: 1, paddingRight: 1, paddingTop: 1, paddingBottom: 1, inline: true, fontSize: 12,  color: '#868e96', borderWidth: 1.5
                }}>
                {desktopAsideOpened ? <ChevronRightIcon fontSize="small" /> : <ChevronLeftIcon fontSize="small" />}
              </Button>

            </div>
            {!desktopAsideOpened && <div
              style={{
                opacity: 0.5,
                borderLeft: 'solid #868e96',
                borderLeftColor: '#868e96',
                height: "100%",
                top: 0,
                right: 280,
                zIndex: 400,
                position: 'absolute',
              }} />}

      {/* SCENE CONTENT */}     
      <Tabs.Panel value="scenes" pb={0} h={'70vh'}>
      <AppShellSection p={5} pb={40} ml={0} mb={0}
      style={{ flexGrow:1, maxHeight:'70vh', overflowX:"hidden",
        overflowY:"scroll"}}>
      <ArtboardPanel projectKey={projectKey} mobile={true} /> 
      </AppShellSection>
      </Tabs.Panel>
      </Tabs>

     {/* OTHERS – want this to expand contract as it gets taller and overlap the Scene Content section above */}
      <AppShellSection mb={0} bottom={0} bg={'#F9F9F9'} 
      style={{flexGrow:2,
        maxHeight:'30',
        zIndex:9999}}>
      <Divider size="xs" my={0} />
      <Others presence={presence} /> 
      </AppShellSection>
      </AppShellAside>
      </AppShell>
    </>
  )
}

export default EditorPage;



const Others = ({ projectKey, presence }) => {
  const [open, setOpen] = useState(false)
  const [hover, setHover] = useState(false)
  const { currentObjectArtboard } = useObjectStore();


  const bringUsersHere = () => {
    bringToArtboard(currentObjectArtboard)
  }

  const handleOpenClick = (e) => {
    e.preventDefault()
    setOpen(!open)
  } 

const { isEditor } = useAccountStore();

const [modalOpen, setModalOpen] = useState(true) 
 
  return (
     <> 
          {/* <Card style={{ borderRadius:32, position: "absolute", right: 25, height:400, width:210, zIndex: 1000, overflow: 'scroll'}}> */}
          <Grid container 
          style={{width:"100%", paddingRight:15, padding:5, marginLeft:5, marginTop:10}}>
          <Grid item xs={6}><Text c={"#424242"} size={'xs'} fw={700}>USERS</Text></Grid>
          <Grid item xs={4}><Text c={"#424242"} size={'xs'} fw={500}>
            <PeopleAltIcon fontSize="small" /> {presence.length} online</Text></Grid>
          <Grid item xs={2} 
                  style={{cursor: "pointer"}}
                  onClick={() => bringUsersHere()}>
                    <div style={{marginLeft: 10}}>
                    <Tooltip label="Bring users to scene">
              <Text size="xs" c={!hover ? "#424242" : "#868e96"}
                onPointerOver={() => setHover(true)}
                onPointerLeave={() => setHover(false)}>
                <TurnLeftIcon size={'xs'} /></Text>
            </Tooltip></div></Grid>
            <Grid item xs={12} p={0} m={0}>    
        <Divider my={0} mt={'xs'} size="xs" variant="dashed"/>
        </Grid>
        <Grid item xs={12}  style={{overflowY:"scroll", overflowX: "hidden", 
         maxHeight:"15vh", minHeight:'2.5vh', 
         zIndex:9999}}>
          {presence.length < 1 ? 
          <Text c={"#424242"} size={'xs'} fw={500} style={{  marginTop: 15 }}>No other users online</Text> 
          : presence.map(({ update, uniqueRealtimeId }) => (
            <OtherUser 
                    open={open}
                    key={uniqueRealtimeId}
                    uniqueRealtimeId={uniqueRealtimeId}
                    update={update}
                    isMobile={isMobile}  />
                  ))}
                  </Grid>
                  <Grid item xs={12} p={0} m={0}>    
        <Divider my={0} mb={'xs'} size="xs" variant="dashed"/>
        </Grid>
        </Grid>

      </>
  )
}

