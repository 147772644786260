import React, { useState} from 'react';
import { useObjectStore } from "../zustand/objects";
import { Grid, Modal, Box, Typography } from "@mui/material/";

import {ScreenshotGuest} from "./ScreenshotGuest";
import ImagesAPIMapped from "./ImagesAPIMapped";
import { Card, Button,  Image, Text, Group, Badge, ActionIcon } from '@mantine/core';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function ScreenshotModal({cameraGuest, artboard, name, closeMaximisedClick, id}) {

  return (
    <Modal 
      open={true}
      onClose={closeMaximisedClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus
    >
      <Card style={{borderRadius:32, width: "80%", padding:0,
        height: "80%",  top: '50%', overflow:'hidden',
        left: '50%',position: 'absolute',transform: 'translate(-50%, -50%)'}}>
          <ScreenshotGuest cameraGuest={cameraGuest} artboard={artboard} id={id} />
          <div style={{position:'absolute', bottom:0, paddingLeft:20, backgroundColor:'white', height: 40, width:'100%' }}>
         <Text fw={400} size={'sm'}  style={{marginTop:10, marginLeft:10}}>
        <Group justify='space-between'><div><AccountCircleIcon style={{marginRight:5}}/>{name} {` | `}
        {artboard}</div>
        <CloseIcon onClick={closeMaximisedClick} style={{cursor:'pointer', marginRight:20}}/></Group>
        </Text></div>
      </Card>
    </Modal>
  );
} 