import React, { Suspense } from "react";
import { useObjectStore } from "../zustand/objects";
import { Interactive, useXR } from "@react-three/xr";
import { PreviewModel, ShapeTexture, PreviewAIModel, UIModelTexture, PreviewUploadedModel } from "./PreviewModel";
import PreviewText from "./PreviewText";
import PreviewImageObject from "./PreviewImageObject";
import { PreviewFigmaObject } from "./FigmaObject";

export const PreviewObjectsMapped = ({ guestMode, projectKey }) => {

  const {
    objectsAreLoaded,
    artboards,
    currentObjectArtboard,
    updateArtboardAndPreviewCamera,
    shapeHoverChange
  } = useObjectStore();

  if (!objectsAreLoaded) {
    return;
  } else {
    return (
      artboards[currentObjectArtboard].map((mapped, i) => (
        mapped.category !== "text" ?
          (mapped.object === "camera")
            ? "" : (mapped.category === "image") ?
              <PreviewImageObject
                key={i}
                position={mapped.position}
                rotation={mapped.rotation}
                scale={mapped.scale}
                id={mapped.id}
                destination={mapped.destination}
                object={mapped.object}
                category={mapped.category}
                currentObjectArtboard={currentObjectArtboard}
                objectsAreLoaded={objectsAreLoaded}
                colour={mapped.colour}
                pose={mapped.pose}
                image={mapped.image}
                imageSize={mapped.imageSize}
                matrixState={mapped.matrixState}
                curved={mapped.curved}
                curveAmount={mapped.curveAmount}
              /> : (mapped.category === "figma") ?
                <PreviewFigmaObject
                  key={i}
                  position={mapped.position}
                  rotation={mapped.rotation}
                  scale={mapped.scale}
                  id={mapped.id}
                  destination={mapped.destination}
                  object={mapped.object}
                  category={mapped.category}
                  currentObjectArtboard={currentObjectArtboard}
                  objectsAreLoaded={objectsAreLoaded}
                  colour={mapped.colour}
                  figmaID={mapped.figmaID}
                  figmaURL={mapped.figmaURL}
                  figmaSize={mapped.figmaSize}
                  matrixState={mapped.matrixState}
                  curved={mapped.curved}
                  curveAmount={mapped.curveAmount}
                /> :
                                mapped.databaseId && (mapped.category === "ai") ?
                  <PreviewAIModel
                    key={mapped.id}
                    position={mapped.position}
                    rotation={mapped.rotation}
                    scale={mapped.scale}
                    id={mapped.id}
                    destination={mapped.destination}
                    object={mapped.object}
                    prompt={mapped.object}
                    category={mapped.category}
                    url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                    artboard={artboards[currentObjectArtboard]}
                    currentObjectArtboard={currentObjectArtboard}
                    objectsAreLoaded={objectsAreLoaded}
                    colour={mapped.colour}
                    pose={mapped.pose}
                    imageTexture={mapped.imageTexture}
                    isLocked={mapped.isLocked}
                    matrixState={mapped.matrixState}
                    guestMode={guestMode}
                    aiKey={mapped.aiKey}
                    projectKey={projectKey}
                    databaseId={mapped.databaseId}
                    databaseURL={mapped.databaseURL}
                  /> : mapped.uploadedURL && (mapped.category === "uploaded") ?
                    <PreviewUploadedModel
                      key={mapped.id}
                      position={mapped.position}
                      rotation={mapped.rotation}
                      scale={mapped.scale}
                      id={mapped.id}
                      destination={mapped.destination}
                      object={mapped.object}
                      prompt={mapped.object}
                      category={mapped.category}
                      url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                      artboard={artboards[currentObjectArtboard]}
                      currentObjectArtboard={currentObjectArtboard}
                      objectsAreLoaded={objectsAreLoaded}
                      colour={mapped.colour}
                      pose={mapped.pose}
                      imageTexture={mapped.imageTexture}
                      isLocked={mapped.isLocked}
                      matrixState={mapped.matrixState}
                      uploadedURL={mapped.uploadedURL}
                      uploadId={mapped.uploadId}
                    /> :
                    mapped.imageTexture && (mapped.category === "UI") ?
                      <UIModelTexture
                        key={mapped.id}
                        position={mapped.position}
                        rotation={mapped.rotation}
                        scale={mapped.scale}
                        id={mapped.id}
                        destination={mapped.destination}
                        object={mapped.object}
                        category={mapped.category}
                        url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                        artboard={artboards[currentObjectArtboard]}
                        currentObjectArtboard={currentObjectArtboard}
                        objectsAreLoaded={objectsAreLoaded}
                        colour={mapped.colour}
                        pose={mapped.pose}
                        imageTexture={mapped.imageTexture}
                        isLocked={mapped.isLocked}
                        matrixState={mapped.matrixState}
                      />
                      :
                      (mapped.imageTexture && mapped.category === "shape") ?
                        <ShapeTexture
                          key={i}
                          position={mapped.position}
                          rotation={mapped.rotation}
                          scale={mapped.scale}
                          id={mapped.id}
                          destination={mapped.destination}
                          object={mapped.object}
                          category={mapped.category}
                          url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                          artboard={artboards[currentObjectArtboard]}
                          currentObjectArtboard={currentObjectArtboard}
                          objectsAreLoaded={objectsAreLoaded}
                          colour={mapped.colour}
                          pose={mapped.pose}
                          imageTexture={mapped.imageTexture}
                          matrixState={mapped.matrixState}
                        /> :

                        <PreviewModel
                          key={i}
                          position={mapped.position}
                          rotation={mapped.rotation}
                          scale={mapped.scale}
                          id={mapped.id}
                          destination={mapped.destination}
                          object={mapped.object}
                          category={mapped.category}
                          url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                          artboard={artboards[currentObjectArtboard]}
                          currentObjectArtboard={currentObjectArtboard}
                          objectsAreLoaded={objectsAreLoaded}
                          colour={mapped.colour}
                          pose={mapped.pose}
                          imageTexture={mapped.imageTexture}
                          matrixState={mapped.matrixState}
                          curved={mapped.curved}
                          curveAmount={mapped.curveAmount}
                          modelPath={mapped.modelPath}
                        /> :
          <PreviewText
            key={i}
            content={mapped.content}
            position={mapped.position}
            rotation={mapped.rotation}
            scale={mapped.scale}
            id={mapped.id}
            destination={mapped.destination}
            artboard={artboards[currentObjectArtboard]}
            currentObjectArtboard={currentObjectArtboard}
            objectsAreLoaded={objectsAreLoaded}
            colour={mapped.colour}
            bGColour={mapped.bGColour}
            matrixState={mapped.matrixState}
          />

      ))
    )
  }
}

export const VRPreviewObjectsMapped = ({ guestMode, projectKey }) => {

  const {
    objectsAreLoaded,
    artboards,
    currentObjectArtboard,
    updateArtboardAndPreviewCamera,
    updateArtboard,
    shapeHoverChange,
    previewCameraPosition
  } = useObjectStore();

  const { player } = useXR()

  const clickedShape = (destination) => {
    destination && updateArtboard(destination)
    };


  return (
    artboards[currentObjectArtboard].map((mapped, i) => (
      mapped.category !== "text" ?
        (mapped.object === "camera")
          ? "" : (mapped.category === "image") ?
            <Suspense key={i} fallback={null}>
              <Interactive key={i} onSelect={() => clickedShape(mapped.destination)}>
                <PreviewImageObject
                  key={i}
                  position={mapped.position}
                  rotation={mapped.rotation}
                  scale={mapped.scale}
                  id={mapped.id}
                  destination={mapped.destination}
                  object={mapped.object}
                  category={mapped.category}
                  currentObjectArtboard={currentObjectArtboard}
                  objectsAreLoaded={objectsAreLoaded}
                  colour={mapped.colour}
                  pose={mapped.pose}
                  image={mapped.image}
                  imageSize={mapped.imageSize}
                  matrixState={mapped.matrixState}
                  curved={mapped.curved}
                  curveAmount={mapped.curveAmount}
                /></Interactive>
            </Suspense> :
            (mapped.category === "figma") ?
              <Interactive key={i} onSelect={() => clickedShape(mapped.destination)}>
                <PreviewFigmaObject
                  key={i}
                  position={mapped.position}
                  rotation={mapped.rotation}
                  scale={mapped.scale}
                  id={mapped.id}
                  destination={mapped.destination}
                  object={mapped.object}
                  category={mapped.category}
                  currentObjectArtboard={currentObjectArtboard}
                  objectsAreLoaded={objectsAreLoaded}
                  colour={mapped.colour}
                  figmaID={mapped.figmaID}
                  figmaURL={mapped.figmaURL}
                  figmaSize={mapped.figmaSize}
                  matrixState={mapped.matrixState}
                  curved={mapped.curved}
                  curveAmount={mapped.curveAmount}
                  pose={mapped.pose}
                />  </Interactive> :
                mapped.databaseId && (mapped.category === "ai") ?
                <Suspense key={i} fallback={null}>
                  <Interactive key={i} onSelect={() => clickedShape(mapped.destination)}>
                    <PreviewAIModel
                      key={mapped.id}
                      position={mapped.position}
                      rotation={mapped.rotation}
                      scale={mapped.scale}
                      id={mapped.id}
                      destination={mapped.destination}
                      object={mapped.object}
                      prompt={mapped.object}
                      category={mapped.category}
                      url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                      artboard={artboards[currentObjectArtboard]}
                      currentObjectArtboard={currentObjectArtboard}
                      objectsAreLoaded={objectsAreLoaded}
                      colour={mapped.colour}
                      pose={mapped.pose}
                      imageTexture={mapped.imageTexture}
                      isLocked={mapped.isLocked}
                      matrixState={mapped.matrixState}
                      guestMode={guestMode}
                      projectKey={projectKey}
                      aiKey={mapped.aiKey}
                      databaseId={mapped.databaseId}
                      databaseURL={mapped.databaseURL}
                    /></Interactive>
                </Suspense> : mapped.uploadedURL && (mapped.category === "uploaded") ?
                  <Suspense key={i} fallback={null}>
                    <Interactive key={i} onSelect={() => clickedShape(mapped.destination)}>
                      <PreviewUploadedModel
                        key={mapped.id}
                        position={mapped.position}
                        rotation={mapped.rotation}
                        scale={mapped.scale}
                        id={mapped.id}
                        destination={mapped.destination}
                        object={mapped.object}
                        prompt={mapped.object}
                        category={mapped.category}
                        url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                        artboard={artboards[currentObjectArtboard]}
                        currentObjectArtboard={currentObjectArtboard}
                        objectsAreLoaded={objectsAreLoaded}
                        colour={mapped.colour}
                        pose={mapped.pose}
                        imageTexture={mapped.imageTexture}
                        isLocked={mapped.isLocked}
                        matrixState={mapped.matrixState}
                        uploadedURL={mapped.uploadedURL}
                        uploadId={mapped.uploadId}
                      /></Interactive>
                  </Suspense> :
                  mapped.imageTexture && (mapped.category === "UI") ?
                    <Suspense key={i} fallback={null}>
                      <Interactive key={i} onSelect={() => clickedShape(mapped.destination)}>
                        <UIModelTexture
                          key={mapped.id}
                          position={mapped.position}
                          rotation={mapped.rotation}
                          scale={mapped.scale}
                          id={mapped.id}
                          destination={mapped.destination}
                          object={mapped.object}
                          category={mapped.category}
                          url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                          artboard={artboards[currentObjectArtboard]}
                          currentObjectArtboard={currentObjectArtboard}
                          objectsAreLoaded={objectsAreLoaded}
                          colour={mapped.colour}
                          pose={mapped.pose}
                          imageTexture={mapped.imageTexture}
                          matrixState={mapped.matrixState}
                        />
                      </Interactive>
                    </Suspense>
                    :
                    (mapped.imageTexture && mapped.category === "shape") ?
                      <Suspense key={i} fallback={null}>
                        <Interactive key={i} onSelect={() => clickedShape(mapped.destination)}>
                          <ShapeTexture
                            key={mapped.id}
                            position={mapped.position}
                            rotation={mapped.rotation}
                            scale={mapped.scale}
                            id={mapped.id}
                            destination={mapped.destination}
                            object={mapped.object}
                            category={mapped.category}
                            url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                            artboard={artboards[currentObjectArtboard]}
                            currentObjectArtboard={currentObjectArtboard}
                            objectsAreLoaded={objectsAreLoaded}
                            colour={mapped.colour}
                            pose={mapped.pose}
                            imageTexture={mapped.imageTexture}
                            matrixState={mapped.matrixState}
                          />
                        </Interactive>
                      </Suspense>
                      :
                      <Suspense key={i} fallback={null}>
                        <Interactive key={i} onSelect={() => clickedShape(mapped.destination)}>
                          <PreviewModel
                            key={i}
                            position={mapped.position}
                            rotation={mapped.rotation}
                            scale={mapped.scale}
                            id={mapped.id}
                            destination={mapped.destination}
                            object={mapped.object}
                            category={mapped.category}
                            url={"/model/" + mapped.category + "/" + mapped.object + ".glb"}
                            artboard={artboards[currentObjectArtboard]}
                            currentObjectArtboard={currentObjectArtboard}
                            objectsAreLoaded={objectsAreLoaded}
                            colour={mapped.colour}
                            pose={mapped.pose}
                            imageTexture={mapped.imageTexture}
                            matrixState={mapped.matrixState}
                            curved={mapped.curved}
                            curveAmount={mapped.curveAmount}
                            modelPath={mapped.modelPath}
                          />
                        </Interactive>
                      </Suspense>
        :
        <Suspense key={i} fallback={null}>
          <Interactive key={i} onSelect={() => clickedShape(mapped.destination)}>
            <PreviewText
              key={i}
              content={mapped.content}
              position={mapped.position}
              rotation={mapped.rotation}
              scale={mapped.scale}
              id={mapped.id}
              destination={mapped.destination}
              artboard={artboards[currentObjectArtboard]}
              currentObjectArtboard={currentObjectArtboard}
              objectsAreLoaded={objectsAreLoaded}
              colour={mapped.colour}
              bGColour={mapped.bGColour}
              matrixState={mapped.matrixState}
            />
          </Interactive>
        </Suspense>
    ))
  )



}



