import React, { useState, useEffect } from 'react';
import { Grid, Modal, Box, Typography } from "@mui/material/";
import { Text, Button, LoadingOverlay, Switch, TextInput, Group, FileButton, ActionIcon } from '@mantine/core';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useObjectStore } from "../zustand/objects";
import { useAccountStore } from "../zustand/account";
import { getFigmaAuthURL, deleteFigmaSync, getUserFigmaFramesAPI, checkForValidFigmaKey, addFigmaFrameFromLink, deleteFigmaFrameFromAccount, updateFigmaFromID } from "../api.js";

import ImagesAPIMapped from "./ImagesAPIMapped";
import FigmaFramesAPIMapped from "./FigmaFramesAPIMapped"; 

import shortid from "shortid";

export default function FigmaModalContents({ id }) {


    const { 
        addObject,
        currentObjectArtboard,
        arrangeUndoRedo
    } = useObjectStore();

    const [hasValidFigmaKey, setHasValidFigmaKey] = useState()
    const [needsRefresh, setNeedsRefresh] = useState(false)
    const [figmaFrameData, setFigmaFrameData] = useState([])
    const [figmaURLs, setFigmaURLs] = useState([])
    const [readyToMap, setReadyToMap] = useState(false)
    const [alreadyImported, setAlreadyImported] = useState(false)
    const [loading, setLoading] = useState(false)


    const checkFigmaKey = async () => {
        setLoading(true)
        setNeedsRefresh(false)
        checkForValidFigmaKey().then(
            (e) => (e.user === 200) ? getTheFigmaFrames() : setHasValidFigmaKey(false)).then(() => setLoading(false))
    }

    useEffect(() => {
        checkFigmaKey()
    }, []); 

    

    const getTheFigmaFrames = () => {
        setHasValidFigmaKey(true)
        setLoading(false)
        getUserFigmaFramesAPI()
            .then(data => setFigmaURLs([...data]))
            // .then((res) => figmaFrameData.forEach(postFramesAndRequestFrames({ frameID: res.frameID, fileID: res.fileID })) ))
            .then(setReadyToMap(true))
            .catch((error) => {
                console.error("Error:", error);
            })
    }

    

    const rando = [...Array(30)].map(() => Math.random().toString(36)[2]).join('')

    const updateFigmaStateAsync = async () => localStorage.setItem('figmaState', rando)





    
    const popup = (figmaAuthURLResponse) => window.open(figmaAuthURLResponse, "popup", "popup=true")


    const handleAuthSubmit = () => {
        setNeedsRefresh(true)
        updateFigmaStateAsync().then(() => getFigmaAuthURL(rando)).then((res) => popup(res))
    }


    const postFramesAndRequestFrames = (data) => {
        addFigmaFrameFromLink(data).then((e) => (e === 200) && getTheFigmaFrames())
    }

    const figmaImportExists = (fileID, frameID) => figmaURLs.some(figma =>
       figma.fileID.includes(fileID) && figma.frameID.includes(frameID)
    );
 
    // Check if the new object's frameID and fileID already exist in the array.

    const checkChangeInput = (e) => {
        // e.preventDefault()
        const data = e.target.value
        const fileID = data.split('/')
        const frameID = data.split(['node-id='])
        const exists = figmaURLs.some((item) => {
             return data && item.frameID === frameID[0].split("&")[0] && item.fileID === fileID[4];
          });
        //   console.log(frameID[0])
          e.target.value && exists ? setAlreadyImported(true) : setAlreadyImported(false)
    }

    const handleFigmaFrameLinkSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = e.target[0].value
        const fileID = data.split('/')
        const frameID = data.split(['node-id='])
        const exists = figmaURLs.some((item) => {
            return item.frameID === frameID[1].split("&")[0] && item.fileID === fileID[4];
          });
            postFramesAndRequestFrames({ frameID: frameID[1].split("&")[0], fileID: fileID[4] }) 
            
    } 

    const deleteFrameAndRequestAgain = (e) => {
        deleteFigmaFrameFromAccount(e).then(() => getTheFigmaFrames())
    }
    const updateFrameAndRequestAgain = (e) => {
        setLoading(true)
        updateFigmaFromID(e).then(() => getTheFigmaFrames())
    }

    const addFrameObject = (figmaID, figmaURL, figmaSize) => {
        setLoading(true)
        const newID = shortid.generate()
        // send update to zustand first (so we have state to grab for undo array)
        addObject({ id: newID, currentObjectArtboard: currentObjectArtboard, undo: false, category: "figma", figmaID: figmaID, figmaURL: figmaURL, figmaSize: figmaSize, object: "figma", imageTexture: null, image: null, bGColour: null,  pose: { name: figmaID, modelPath: figmaURL} })
        // send current state to undoActions
        arrangeUndoRedo({
          updateType: "objectAdd",
          artboard: currentObjectArtboard,
          objectID: newID,
          undo: false
        })
        setLoading(false)
        
    }

    const [deleting, setDeleting] = useState(false)
    const handleDeleteFigmaSyncClick = () => {
        deleteFigmaSync()
        .then(() => setDeleting(true))
        .then(() => checkFigmaKey())
        .then(() => setDeleting(false))
    }

   
// can we organise thumbnails by file name? and maybe page?
// enable refresh xano function 

    return ( 
        <div style={{ paddingBottom: 10 }}>
             <LoadingOverlay loaderProps={{ color: 'black' }} visible={deleting} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
            <LoadingOverlay loaderProps={{ color: 'black' }} visible={loading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
        <Grid container style={{ width:'100%', padding:20, paddingTop:10, margin:0, height:190, overflow:'scroll', backgroundImage: 'url("/image/background-grid.png")' }}>
                                {hasValidFigmaKey && figmaURLs.map((figmaData, i) =>
                                    <Grid key={i} item xs={4}  >
                                        <div key={i} style={{ height: "100px", overflow: "hidden", margin: 5 }}>
                                            <img key={i} alt={'uploaded content'} style={{ width: "100%" }} src={`https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/figmaframes/${figmaData.id}/proxy`} />
                                            {/* <img key={i} alt={'uploaded content'} style={{ width: "100%" }} src={figmaData.image.url} /> */}
                                        </div>
                                        <div>
                                        </div>
                                        <Group>
                                        <Button onClick={() => addFrameObject(figmaData.id, `https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/figmaframes/${figmaData.id}/proxy`, figmaData.size, figmaData.id)}
                                            style={{ height: 20, paddingTop: 1, paddingBottom: 1, inline: true, fontSize: 12, backgroundColor: 'black', borderRadius: 32}}>
                                            Add
                                        </Button>
                                             <ActionIcon variant="transparent" color="black" size={'xs'}
                                            onClick={() => updateFrameAndRequestAgain(figmaData.id)}>
                                            <CachedIcon />
                                        </ActionIcon>
                                        <ActionIcon variant="transparent" color="black" size={'xs'}
                                            onClick={() => deleteFrameAndRequestAgain(figmaData.id)}>
                                            <DeleteIcon />
                                        </ActionIcon>
                                        </Group>
                                    </Grid>
                                )}
                                 </Grid>
        <Grid container style={{margin: 5, padding:20, paddingTop: 0}}>
           
            <Grid item xs={12}>
                    <Group justify="space-between">
                        <Text size="xl" fw={700}>
                            Import a Figma frame
                        </Text>
                       {needsRefresh ?
                        <Button 
                        style={{
                            width: 180, inline: true, backgroundColor: 'black', borderRadius: 32, marginRight: 3
                        }} color='black'
                        onClick={() => checkFigmaKey()}>Check Figma access</Button> :

                        hasValidFigmaKey ? <Button mt={10} mb={10}
                            style={{
                                width: 140, inline: true, backgroundColor: 'black', borderRadius: 32, marginRight: 3
                            }} color='black'
                            onClick={() => handleDeleteFigmaSyncClick()}>Unlink account</Button> :

                            <Button style={{
                                width: 140, inline: true, backgroundColor: 'black', borderRadius: 32, marginRight: 3
                            }} color='black' onClick={() => handleAuthSubmit()}>Link account</Button>}
                    </Group>
                    {/* <Grid item xs={12}>
                        <Text size="md" fw={400}>
                            Some firewalls block embedded Figma frames.
                </Text>
                </Grid> */}
                <Grid item xs={12}>
                    {/* {needsRefresh ?
                        <Button 
                        style={{
                            width: 180, inline: true, backgroundColor: 'black', borderRadius: 32, marginRight: 3
                        }} color='black'
                        onClick={() => checkFigmaKey()}>Check Figma access</Button> : */}
                        {hasValidFigmaKey &&
                            <>
                            <form onSubmit={e => handleFigmaFrameLinkSubmit(e)} onChange={e => checkChangeInput(e)}>
                                <label>Paste in Figma frame link:</label>
                                <Group><TextInput radius={32} size="md" type="text" id="frameLink" name="frameLink" />
                                    <Button style={{
                                        width: 100, inline: true, backgroundColor: 'black', borderRadius: 32, marginRight: 3
                                    }} color='black' type="submit" disabled={alreadyImported}>Submit</Button></Group>
                                {alreadyImported && <div>Frame already linked.</div>}
                            </form>
                            </>                            
                    }

                </Grid>
            </Grid>
        </Grid>
        </div>
    )
}