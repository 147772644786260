import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Loader } from "@mantine/core"


const Profile = () => {
  // const { user, isAuthenticated, isLoading } = useAuth0();

  // if (isLoading) {
  //   return <div style={{position:"absolute", left:"50%", top:"50%", width:"100%", height:"100%"}}><Loader color="black" /></div>;
  // }

  const GridBackground = () => {
    return (
        <div style={{ position: "fixed", left: 0, top: 0, bottom: 0, zIndex: -1, width: "100%", height: "100vh", backgroundImage: 'url("/image/background-grid.png")', backgroundAttachment: "fixed" }}></div>

    )
}



  return (
    // isAuthenticated && (
      <div>
        <GridBackground />
        {/* <img src={user.picture} alt={user.name} />
        <h2>{user.name}</h2>
        <p>{user.email}</p> */}
      </div>
    // )
  );
};

export default Profile;