import React, { useState, useEffect, Suspense } from 'react';
import { useObjectStore } from "../zustand/objects";
import { Grid, Modal } from "@mui/material/";
import DeleteIcon from "@mui/icons-material/Delete"

import { Card, Button, Text, Group, FileButton, ActionIcon, Tabs } from '@mantine/core';

import StagedUploadedModel from './StagedUploadedModel'
import ImagesAPIMapped from "./ImagesAPIMapped";

import shortid from "shortid";
 

export function UploadModal({ open, close }) {
  
  const {
    arrangeUndoRedo,
    currentObjectArtboard,
    addObject
  } = useObjectStore();


const [myModels, setMyModels] = useState([])


//  GET ALL UPLOADED MODELS
const getUserModelsAPI = () => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/model', {
    method: 'GET',
    headers: {
      "content-type": "application/json",
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
  })
    .then((response) => response.json())
    .then((data) => {
    setMyModels(data)
    })
    .catch((error) => {
      console.error("Error:", error);

    });
}

const checkModelCount = () => {
  // console.log(myModels.length)
  myModels.length >= 5 ? setUploadAlert(true) : setUploadAlert(false)
}

  useEffect(() => {
    getUserModelsAPI()
  }, [])

  useEffect(() => {
    checkModelCount()
  }, [myModels.length])


 
const uploadModelAPI = (data) => {
  
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/model', {
    method: 'POST',
    headers: {
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
    body: data

  })
    .then((response) => response.json())
    .then((data) => {
      getUserModelsAPI()
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}


const handleDeleteAPI = (data) => {
  return fetch('https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/model/' + data, {
    method: 'DELETE',
    headers: {
      "Authorization": `Bearer ${localStorage.getItem('user')}`
    },
    body: data

  })
    .then((response) => response.json())
    .then(() => {
      getUserModelsAPI()
    })
    .catch((error) => {
      console.error("Error:", error); 
    });
}
 

//  add it to scene 
const handleSelect = (e) => {  
  const newID = shortid.generate()
  // send update to zustand first (so we have state to grab for undo array)
  addObject({
    currentObjectArtboard,
    category: 'uploaded',
    object: e.name,
    undo: false,
    id: newID, 
    position: null,
    scale: null,
    rotation: null,
    matrix: null,
    pose: {
      name: null,
      modelPath: e.model.url
    },
    imageTexture: null,
    image: null,
    curved: false,
    curvedAmount: 3,
    uploadedURL: e.model.url,
    bGColour: null,
    uploadId: e.id
  })
  // send current state to undoActions
  arrangeUndoRedo({
      updateType: "objectAdd",
      artboard: currentObjectArtboard,
      objectID: newID,
      undo: false
  })
  
  }

  const [sizeAlert, setSizeAlert] = useState(false)
  const [uploadAlert, setUploadAlert] = useState(false)

    
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card style={{borderRadius:32, width: 500, padding:0,
        height: 360,  top: '50%', overflow:'hidden',
        left: '50%',position: 'absolute',transform: 'translate(-50%, -50%)'}}>

        <Tabs color="black" variant="pills" defaultValue="models">
          <Tabs.List style={{height:50, width:"100%"}}>
            <Tabs.Tab value="models" radius={"xs"} fw={700} w={"49%"}>
              Models
            </Tabs.Tab>
            <Tabs.Tab value="images" radius={"xs"} fw={700} w={"49%"}>
              Images
            </Tabs.Tab>
          </Tabs.List>

        {/* MODELS */}
          <Tabs.Panel value="models" style={{height:360}}>    
        <Grid container style={{ width:'100%', padding:20, paddingTop:10, margin:0, height:190, overflow:'scroll', backgroundImage: 'url("/image/background-grid.png")' }}>

          {myModels.map((model, i) => (

            <Grid key={i} item xs={4} style={{ marginTop: 5 }} mb={2.5}>

                <Suspense fallback={null}>
                  <StagedUploadedModel url={model.model.url} /></Suspense>


                <Text fw={500}>{model.name}</Text>
                <Group style={{marginTop: 5}}>
                  <Button onClick={() => handleSelect(model)}
                    style={{
                      height: 20, paddingTop: 1, paddingBottom: 1, inline: true, fontSize: 12, backgroundColor: 'black', borderRadius: 32
                    }}>
                    Add
                  </Button>
                  <ActionIcon variant="transparent" color="black" size={'xs'}
                  onClick={() => handleDeleteAPI(model.id)}>
                    <DeleteIcon />
                  </ActionIcon></Group>
            </Grid>
          ))}         
        </Grid>
        <Grid container style={{padding:20}}>
        <Grid item  xs={12}>
        <Text size="xl" fw={700}>Upload your own model</Text>
        
       <Group style={{marginTop:10}}>
        <FileButton accept=".glb"
        
          onChange={(e) => {
          const form = new FormData()
          if (e.size > 1097152) {setSizeAlert(true)}
          else {setSizeAlert(false)
          form.append('model', e, e.name.substring(0,15) + ".glb");
          e && uploadModelAPI(form)}
        }} 
          >
          {(props) => <Button disabled={uploadAlert}
          style={{ paddingTop: 1, paddingBottom: 1, inline: true,  backgroundColor: 'black', borderRadius: 32
          }}
           {...props}>Upload</Button>}
        </FileButton>
        <Text size="md" fw={400}>Only .glb files are accepted.</Text>
        {sizeAlert && <Text c={"red"} size="md" fw={400}>Max size 1mb</Text>}
        {uploadAlert && <Text c={"red"} size="md" fw={400}>Max 5 uploads</Text>}
        </Group>
        </Grid>
        </Grid>
        </Tabs.Panel>

        {/* IMAGES */}
        <Tabs.Panel value="images" >    
          <ImagesAPIMapped/>
        </Tabs.Panel>
        </Tabs>
      </Card>
    </Modal>
  );
}

