import React, { useRef } from "react";
import * as THREE from "three";
import { Cylinder, Edges } from '@react-three/drei'

export default function Cursor({ rotation, position, color, mouse }) {

  const myGeometry = useRef()

  return (
    <group
      position={position && [position.x / 2, position.y / 2, position.z / 2]}
      rotation={rotation && new THREE.Euler(rotation._x, rotation._y, rotation._z, 'XYZ')}
    >
      <Cylinder
        receiveShadow material-color={color} material={new THREE.MeshStandardMaterial} 
        ref={myGeometry}
        args={[0.1, 0, 0.5]}
        position={mouse && [mouse.x * 1.5, position.y > 0 ? mouse.y * 1.5 : 1, position.z > 2 ? -5 : 0]}
        rotation={[2, 0, 0]}
      >
        <Edges
                    scale={1}
                    threshold={20} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
                    color="grey"
                />
                </Cylinder>
    </group>
  )
}