import React, {Suspense} from 'react';
import { Canvas } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";

export default function StagedUploadedModel({url}) {

    const gltf = useGLTF(url)

    return (
        <Canvas style={{width: 100, height:100}} shadows>
                      <ambientLight intensity={0.6} />
                      {/* <Resize width> */}
                <Suspense fallback={null}>
              <primitive scale={5} rotation={[0,-0.25,0]} object={gltf.scene} position={[0, -5, -10]} />
              </Suspense>
              {/* </Resize> */}
        </Canvas>

    );
} 