import React, {useEffect, useState} from "react";
import { Grid } from "@mui/material/";
import AppNavBar from "../components/AppNavBar";
import { MiroShareButton, GoogleShareButton } from "../components/ShareModal";
import { useAccountStore } from "../zustand/account"; 
import { deleteUserAndSubs, getUserAccount, getMiroSyncEntry, getGoogleSyncEntry, deleteUserMiroEntry, deleteUserGoogleEntry, getAuth0URL, getStripeCheckoutURL } from "../api"; 
import Container from '@mui/material/Container';

import { LoadingOverlay, Card, Stack, Text, Group, Badge, Button, ActionIcon } from '@mantine/core';

 
const AccountPage = () => {

    const GridBackground = () => {
        return (
            <div style={{ position: "absolute", left: 0, top: 0, bottom: 0, zIndex: -1, width: "100%", height: "100%", backgroundImage: 'url("/image/background-grid.png")', backgroundAttachment: "fixed" }}></div>

        ) 
    }

    const { logUserOut, isAuthUser, userStatus, checkAuthTokenValidSubscription} = useAccountStore();

    
    const [haveEntry, setHaveEntry] = useState(false)
    const [haveGoogleEntry, setHaveGoogleEntry] = useState(false)
    const [loading, setLoading] = useState(true)
    const [createdAt, setCreatedAt] = useState()
    const [lastLoginDate, setLastLoginDate] = useState()
    const [userName, setUserName] = useState()
    const [userEmail, setUserEmail] = useState()
    const [confirmDelete, setConfirmDelete] = useState(false)

    useEffect(() => {
        getMiroSyncEntry().then((res) => { res === 200 && setHaveEntry(true) })
        getGoogleSyncEntry().then((res) => { res === 200 && setHaveGoogleEntry(true) })
        getUserAccount().then((res) => {
            setCreatedAt(new Date(res.created_at).toDateString())
            setLastLoginDate(new Date(res.lastLoggedIn).toDateString())
            setUserName(res.auth0_oauth.name)
            setUserEmail(res.email)
            setLoading(false)
        })
    },[])

    const deleteEntry = () => {
        deleteUserMiroEntry()
        setHaveEntry(false)
      }
      const deleteGoogleSlidesEntry = () => {
        deleteUserGoogleEntry()
        setHaveGoogleEntry(false)
      }
    

      const handleLoginClick = () => {
        getAuth0URL('https://mbxr.io/logging-in').then((res) => window.location.assign(res.authUrl))
    }

    const handleSubscriptionClick = (label) => {

      getStripeCheckoutURL({
          success_url: 'https://mbxr.io/projects',
          cancel_url: 'https://mbxr.io/logging-out',
          // success_url: 'http://localhost:3000/projects',
          // cancel_url: 'http://localhost:3000/membership',
          subscription: label
      })
      // .then((res) => console.log(res.url))
      .then((res) => window.location.replace(res.url))}
  
const handleDeleteClick = () => {
  deleteUserAndSubs().then(() => checkAuthTokenValidSubscription()).then((res) => window.location.replace('https://mbxr.io/login'))
}
 
const handleSubscriptionManageClick = () => {
   window.location.replace('https://billing.stripe.com/p/login/6oE3eqdo4dVJf1SaEE')
}
 
    return (
        <>
        <AppNavBar pageName={"Account Settings"} />
         <Container component="main" maxWidth="lg">
        <GridBackground />
            <Grid container> 
            {/* {userStatus === 'loggedIn-none' && <SubscribeReminderBar/>} */}
                <Grid item xs={12}mt={2.5}>
                    <LoadingOverlay loaderProps={{ color: 'black' }} visible={loading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
              {userStatus === 'loggedOut' ? 
                (
                  <Button onClick={() => handleLoginClick()}
                    size="xl" variant="contained"
                    style={{ margin: 30, backgroundColor: "#FAC710", boxShadow: "none", color: 'black', borderRadius: 0, borderStyle: "outset", padding: 4 }}>
                    Login/register</Button>
                ) :
                    userStatus === 'loggedIn-active' ? (
                        <>
                            <Text fw={700} size={'lg'}>
                            Details
                            </Text>
                           <Text fw={500} size={'sm'}>
                                Created on: {createdAt && createdAt}
                            </Text>
                            <Text fw={500} size={'sm'}>
                                Last login: {lastLoginDate && lastLoginDate}
                            </Text>
                            <Text fw={500} size={'sm'}>
                                Email: {!loading && userEmail && userEmail}
                            </Text>
                            <Text fw={500} size={'sm'}>
                                Contact michael@matchboxxr.com for support.
                            </Text>
                        </>)
                        :
                        (<>
                            <Text fw={700} size={'lg'}>
                            Details
                            </Text>
                           <Text fw={500} size={'sm'}>
                                Created on: {createdAt && createdAt}
                            </Text>
                            <Text fw={500} size={'sm'}>
                                Email: {!loading && userEmail && userEmail}
                            </Text>
                            <Text fw={500} size={'sm'}>
                                You are on the free plan.
                            </Text>
                        </>)
                        }


                </Grid>
                {userStatus !== 'loggedOut' && <Grid item xs={12} mt={2.5}>
                <Text fw={700} size={'lg'}>
                            Actions
                            </Text>
               {haveEntry && <><Button onClick={() => deleteEntry()}
              style={{
                width: 120, marginTop: 5, marginBottom: 20, paddingBottom: 1, inline: true, backgroundColor: 'black', borderRadius: 32
              }}>Unlink Miro</Button></>}
              {haveGoogleEntry && <><Button
              onClick={() => deleteGoogleSlidesEntry()}
              style={{
                width: 150, marginTop: 5, marginBottom: 20, paddingBottom: 1, inline: true, backgroundColor: 'black', borderRadius: 32
              }}>Unlink Google</Button></>}
              {userStatus === 'loggedIn-active' ? <Button
              component="a"
              href="https://billing.stripe.com/p/login/6oE3eqdo4dVJf1SaEE"
              target="_blank"
              // onClick={() => handleSubscriptionManageClick()}
              style={{
                width: 160, marginTop: 5, marginRight:20, marginBottom: 20, paddingBottom: 1, inline: true, backgroundColor: 'black', borderRadius: 32
              }}>Manage paid plan</Button> : <Button onClick={() => handleSubscriptionClick("pioneer")}
              variant="contained"
              style={{
               width: 170, marginTop: 5, marginRight:20, marginBottom: 20, paddingBottom: 1, inline: true, backgroundColor: '#FAC710', borderRadius: 32
             }}>
               Upgrade</Button>} 
              {!confirmDelete ? <><Button
              onClick={() => setConfirmDelete(true)}
              style={{
                width: 160, marginTop: 5, marginRight:20, marginBottom: 20, paddingBottom: 1, inline: true, backgroundColor: 'red', borderRadius: 32
              }}>DELETE ACCOUNT</Button></> :
              <><Text fw={500} size={'sm'}>
                                Are you sure you want to delete your account?
                            </Text>
              <Button
              onClick={() => handleDeleteClick()}
              style={{
                width: 170, marginTop: 5, marginRight: 20, marginBottom: 20, paddingBottom: 1, inline: true, backgroundColor: 'red', borderRadius: 32
              }}>DELETE ACCOUNT</Button>
              <Button
              onClick={() => setConfirmDelete(false)}
              style={{
                width: 160, marginTop: 5, marginBottom: 20, paddingBottom: 1, inline: true, backgroundColor: 'black', borderRadius: 32
              }}>No</Button>
              <Text fw={500} size={'sm'}>
                Deleting your account will cancel your paid subscription, remove integration data from matchboxxr and permanently delete all associated projects and files.
            </Text></>}
              </Grid>}
            </Grid>
            </Container></>
    )
};


export default AccountPage;
