import React, { useEffect, useState } from "react";
import { MenuItem } from "@mui/material/";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import { useObjectStore } from "../zustand/objects";
import { Select, Portal } from '@mantine/core';

import ModelPoses from "../ModelPoses.json";

import {getPoses} from "../api.js"

const PosesButton = ({ pose, object, id }) => {
  
  const { updatePose, currentObjectArtboard, arrangeUndoRedo } = useObjectStore();
  
  const [loading, setLoading] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = ({id}) => {
    setAnchorEl(null);
  };

  const posesObject = {}

  const handleSelectUIImage = (name, path) => {
    // send current state to undoActions
    name && arrangeUndoRedo({
      updateType: "objectUpdate", 
      artboard: currentObjectArtboard, 
      objectID: id,
      undo:false,
      field: "pose"
      })
      // send update to zustand
     name && convertArrayToObject(poses).then(() => updatePose({
      id:id,
      currentObjectArtboard:currentObjectArtboard,
      pose: {
        name:name,
        modelPath: path
      },
      undo: false
    }))
  } 
  const handleSelectObject = (name) => {
  // send current state to undoActions
    name && arrangeUndoRedo({
     updateType: "objectUpdate", 
     artboard: currentObjectArtboard, 
     objectID: id,
     undo:false,
     field: "pose"
     })
     // send update to zustand
     name && convertArrayToObject(poses).then(() => updatePose({
      id:id,
      currentObjectArtboard:currentObjectArtboard,
      pose: {
        name: name,
        modelPath: posesObject[name].transformed_file.path
      },
      undo: false
    }))
  } 

  

  const [poses, setPoses] = useState([])
  

async function convertArrayToObject(array) {
    const object = {};
    for (const item of array) {
      posesObject[item.name] = item;
    }
    return object;
  }
  

  useEffect(() => {
    getPoses(object).then((e) => setPoses(e))
  },[])

  return (
    <div style={{ paddingTop: 8, paddingBottom:8, height: "100%", display: 'flex', justifyContent: 'flex-start', zindex: 1600, overflow: 'visible' }}>
   {ModelPoses[object] ? <Select
      radius={16}
      size={'xs'}
      label="Mode"
      defaultValue={pose.name}
      data={[...ModelPoses[object]]}
      onChange={(e) =>   handleSelectUIImage(e, null)}
      // style={{dropdown: {width: "100%"}, input: {width: 120}}}
    /> :
   poses.length > 0 && <Select
      radius={16}
      size={'xs'}
      label="Mode"
      defaultValue={pose.name}
      data={poses.map((obj) => (obj.name))
      }
      onChange={(e) => handleSelectObject(e)}
      // style={{dropdown: {width: "100%"}, input: {width: 120}}}      
      // onChange={(e) => handleSelect(e,  posesObject.e.transformed_file.path)}
    />}

  </div>
 

  );
};

export default PosesButton;










