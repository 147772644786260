import React, {useEffect, useState} from "react";
import { useObjectStore } from "../zustand/objects";
import {Box} from "@mui/material/";
import RedoIcon from '@mui/icons-material/Redo';
import { Card, Button,  Switch, Text, Group, Slider, ActionIcon } from '@mantine/core';


const RedoButton = () => {
  const {
    redoActions,
    arrangeUndoRedo,
    sendUpdateToChannel
  } = useObjectStore();


  const asyncRedo = async (data) => {
    arrangeUndoRedo(data)
   }


  const handleRedoClick = () => {
    const data = redoActions[0]
    asyncRedo(data).then(() => 
    sendUpdateToChannel(data))
  }


  return (
    <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          height: "100%",
          margin: "2.5px",
          paddingBottom: 0.5
        }}>
          <ActionIcon disabled={redoActions.length <= 0}
          size={'sm'}
          onClick={() => handleRedoClick()}
          // onClick={() => redoRemake()}
          variant="transparent"
          color="#424242"
          bg={'none'}
          >
            <RedoIcon fontSize={'small'} />
          </ActionIcon>
          {/* <button style={{backgroundColor: "none"}} disabled={redoActions.length <= 0} size={"small"} onClick={() => redoRemake()}>redo</button> */}
      </Box>
  );
};

export default RedoButton;
