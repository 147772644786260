import React from "react";
import { useObjectStore } from "../zustand/objects";
import { Button, Box } from "@mui/material/";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const ChangePreviewArtboard = () => {
    const {
        currentObjectArtboard,
        updateArtboard,
        artboards
    } = useObjectStore();

const artboardsAmount = Object.keys(artboards).length

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                height: "100%",
            }}>
            <Button  style={{ height: "100%", backgroundColor: "white", boxShadow: "none", color: 'black' }} disabled={parseInt(currentObjectArtboard, 10) === 1}  startIcon={<ArrowBackIcon />} variant="contained" onClick={ () => updateArtboard(Number(currentObjectArtboard) - 1)} >Back</Button>
            <Button  style={{ height: "100%", backgroundColor: "white", boxShadow: "none", color: 'black' }} disabled={artboardsAmount <= parseInt(currentObjectArtboard, 10)} startIcon={<ArrowForwardIcon />} variant="contained"  onClick={ () => updateArtboard(Number(currentObjectArtboard) + 1)} >Next</Button>
        </Box>
    ); 
};

export default ChangePreviewArtboard;
