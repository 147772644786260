/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { Box, useTexture, Edges } from '@react-three/drei'
import * as THREE from "three";

export function Button({ ...props }) {
  const group = useRef()

  return (
      <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
          props.clickFunction()}}>
          <Box position={[0,0.5,0]} args={[0.25, 0.1, 0.0125]} radius={0.05} smoothness={4} >
              <meshPhongMaterial
                  receiveShadow
                  color={props.colour}
                  attach="material"
                  opacity={1}
                  
              ><Edges
    scale={1}
    threshold={15} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
    color="black"
  /></meshPhongMaterial>
          </Box>
      </group>
  )
}

export function ButtonTexture({ ...props }) {

  const texture = useTexture(props.imageTexture)
  const group = useRef()

  return (
      <group visible={props.colour ? true : false} ref={group} {...props} dispose={null} onClick={(e) => {e.stopPropagation() 
          props.clickFunction()}}>
          <Box position={[0,0.5,0]} args={[0.25, 0.1, 0.0125]} radius={0.05} smoothness={4} >
              <meshPhongMaterial
                  receiveShadow
                  color={props.colour}
                  attach="material"
                  opacity={1} transparent
                  map={texture}

                  
              ><Edges
    scale={1}
    threshold={15} // Display edges only when the angle between two faces exceeds this value (default=15 degrees)
    color="black"
  /></meshPhongMaterial>
          </Box>
      </group>
  )
}
