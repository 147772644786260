import React, { useRef, useState } from "react";
import { useXR, useXREvent, useInteraction } from "@react-three/xr";
import { useFrame } from "@react-three/fiber";

import { useObjectStore } from "../zustand/objects";
import * as THREE from "three";

const VRControls = (props) => {
    const { updateObjectMatrix, setIsMovingVRObject } = useObjectStore();
  const meshRef = useRef();
  const boxRef = useRef();

  const [hovered, setHovered] = useState(false);
  const [pressed, setPressed] = useState(false);
  const [dragging, setDragging] = useState(false);

  const [controllerStartPos, setControllerStartPos] = useState(null);
  const [controllerStartRot, setControllerStartRot] = useState(null);
  const [boxStartPos, setBoxStartPos] = useState(null);
  const [controllerPrevPos, setControllerPrevPos] = useState(null);
  const [controllerPrevRot, setControllerPrevRot] = useState(null);
  const [isMoving, setMoving] = useState(false);
  const [initialDistance, setInitialDistance] = useState(0);

  // Use useXR hook to get controller information
  const { controllers, isPresenting } = useXR();

  useXREvent("selectstart", (event) => {
    !props.isLocked && setPressed(true);
    if (hovered) {
      setDragging(true);
      setControllerStartPos(event.target.controller.position.clone());
      // rotation is weird, so rotation on y axis would move an object along its x axis (we think)
      // not minusing '_x' because it would mean pointing controller down would push model up
      setControllerStartRot(new THREE.Vector3(-event.target.controller.rotation._y,event.target.controller.rotation._x,0));
      setBoxStartPos(meshRef.current.position.clone());
      setControllerPrevPos(event.target.controller.position.clone());
      setControllerPrevRot(new THREE.Vector3(-event.target.controller.rotation._y,event.target.controller.rotation._x,0));
      // setControllerRayIntersectPrevPos(new THREE.Vector3(event.target.controller.children[0].matrixWorld.elements[12],event.target.controller.children[0].matrixWorld.elements[13],event.target.controller.children[0].matrixWorld.elements[14]));
      setMoving(false);
    }
  });

  useXREvent("selectend", (event) => {
    setPressed(false);
    setDragging(false);
    setMoving(false);
    setIsMovingVRObject(false)
  });
  
  const groupRef = useRef();

  useInteraction(groupRef, "onHover", (event) => {
    !props.isLocked && setHovered(true)
    !props.isLocked && setIsMovingVRObject(true)
    

    // console.log(event.intersections[0])
  });
  
  useInteraction(groupRef, "onBlur", (event) => setHovered(false));
  // console.log(props.matrixState)
  // Update box position based on controller movement
  useFrame(() => {
    if (controllers.length > 1) { 
      if (dragging) {
        const controller = controllers[1];
        const controllerCurrentPos = controller.controller.position.clone();
        // maybe up down rotate should make it back or forwards
        const controllerCurrentRot = new THREE.Vector3(-controller.controller.rotation._y,controller.controller.rotation._x,0);
        const controllerDelta = controllerCurrentPos.clone().sub(controllerPrevPos);
        const controllerRotDelta = controllerCurrentRot.clone().sub(controllerPrevRot);
        const distance = controllerCurrentPos.distanceTo(controllerStartPos);
        const rotDistance = controllerCurrentRot.distanceTo(controllerStartRot);
        // console.log(meshRef.current)
        if (!isMoving && distance > 0.001) {
          setInitialDistance(distance);
          setMoving(true);
        } 
        if (!isMoving && rotDistance > 0.001) {
          setInitialDistance(rotDistance);
          setMoving(true);
        }
        if (isMoving) {
          const newPosition = meshRef.current.position.clone().add(new THREE.Vector3(controllerDelta.x + controllerRotDelta.x,controllerDelta.y + controllerRotDelta.y,controllerDelta.z + controllerRotDelta.z));
          // console.log(meshRef.current.matrixWorld)
          // console.log(props.matrixState)
          const matrixToSend = new THREE.Matrix4()
          updateObjectMatrix({
            id: props.idprop,
            currentObjectArtboard: props.currentObjectArtboard,
            matrix: matrixToSend.compose(newPosition, props.rot, props.scale),
            // matrix: new THREE.Matrix4().copy(meshRef.current.matrixWorld.makeTranslation(newPosition.toArray()[0],newPosition.toArray()[1],newPosition.toArray()[2])),
            // matrix: new THREE.Matrix4().copy(matrixToSend.makeTranslation(newPosition.toArray()[0],newPosition.toArray()[1],newPosition.toArray()[2])),
            undo: false
          })
          // console.log(meshRef.current.matrixWorld)
          // console.log(props.matrixState)
        }
        setControllerPrevPos(controllerCurrentPos.clone());
        setControllerPrevRot(controllerCurrentRot.clone());
      }
    }
   else if (controllers.length < 2) { 
      if (dragging) {
        const controller = controllers[0];
        const controllerCurrentPos = controller.controller.position.clone();
        // maybe up down rotate should make it back or forwards
        const controllerCurrentRot = new THREE.Vector3(-controller.controller.rotation._y,controller.controller.rotation._x,0);
        const controllerDelta = controllerCurrentPos.clone().sub(controllerPrevPos);
        const controllerRotDelta = controllerCurrentRot.clone().sub(controllerPrevRot);
        const distance = controllerCurrentPos.distanceTo(controllerStartPos);
        const rotDistance = controllerCurrentRot.distanceTo(controllerStartRot);
        // console.log(meshRef.current)
        if (!isMoving && distance > 0.001) {
          setInitialDistance(distance);
          setMoving(true);
        }
        if (!isMoving && rotDistance > 0.001) {
          setInitialDistance(rotDistance);
          setMoving(true);
        }
        if (isMoving) {
          const newPosition = meshRef.current.position.clone().add(new THREE.Vector3(controllerDelta.x + controllerRotDelta.x,controllerDelta.y + controllerRotDelta.y,controllerDelta.z + controllerRotDelta.z));
          // console.log(meshRef.current.matrixWorld)
          // console.log(props.matrixState)
          const matrixToSend = new THREE.Matrix4()
          updateObjectMatrix({
            id: props.idprop,
            currentObjectArtboard: props.currentObjectArtboard,
            matrix: matrixToSend.compose(newPosition, props.rot, props.scale),
            // matrix: new THREE.Matrix4().copy(meshRef.current.matrixWorld.makeTranslation(newPosition.toArray()[0],newPosition.toArray()[1],newPosition.toArray()[2])),
            // matrix: new THREE.Matrix4().copy(matrixToSend.makeTranslation(newPosition.toArray()[0],newPosition.toArray()[1],newPosition.toArray()[2])),
            undo: false
          })
          
        }
        setControllerPrevPos(controllerCurrentPos.clone());
        setControllerPrevRot(controllerCurrentRot.clone());
      }
    }
  });
   
  const [vrControlsValue, setVrControlsValue] = useState(null)

  // declare empty position, rotation and scale for .decompose()
const pos = new THREE.Vector3()
const rot = new THREE.Quaternion()
const sca = new THREE.Vector3()

  // decompose newMatrix and apply to pos, rot and sca
  props.matrixState.decompose(pos, rot, sca)
  // props.matrixState.decompose(pos, rot, sca)


  return (
    <group {...props} ref={meshRef} 
    //   position={props.objectsAreLoaded && new THREE.Vector3().setFromMatrixPosition(props.matrixState)}
    //   rotation={props.objectsAreLoaded && new THREE.Euler().setFromRotationMatrix(props.matrixState)}
    //   // rotation={props.objectsAreLoaded && new THREE.Euler().setFromQuaternion(props.rot)}
    // scale={props.objectsAreLoaded && new THREE.Vector3().setFromMatrixScale(props.matrixState)}
            scale={new THREE.Vector3().setFromMatrixScale(props.matrixState)}
            position={new THREE.Vector3().setFromMatrixPosition(props.matrixState)}
            rotation={new THREE.Euler().setFromQuaternion(rot)}
      >
      <group ref={groupRef}>
        {props.children}
      </group>
    </group>
  );
}

  export default VRControls