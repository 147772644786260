import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from '@auth0/auth0-react';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
// core styles are required for all packages
import '@mantine/core/styles.css';
import { MantineProvider } from '@mantine/core';

// other css files are required only if
// you are using components from the corresponding package
// import '@mantine/dates/styles.css';
// import '@mantine/dropzone/styles.css';
// import '@mantine/code-highlight/styles.css';
// ...
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://d8fe3ddd62eec2902ecc036299d43341@o4506605582614528.ingest.sentry.io/4506605600309248",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https://mbxr.io", /^\/api\//],
      // tracePropagationTargets: ["localhost", "https://mbxr.io", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ], 
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// initialise firebase
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBHRHn5eDVQ5SIX-8ogvsQdr-EkMFCFgtI",
  authDomain: "matchbox-xr.firebaseapp.com",
  projectId: "matchbox-xr",
  storageBucket: "matchbox-xr.appspot.com",
  messagingSenderId: "143427696415",
  appId: "1:143427696415:web:598ca25bd003147c52a22a",
  measurementId: "G-M0LX7F6ZH5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// use default theme
// const theme = createTheme();

// Or Create your Own theme:
const customTheme = createTheme({
  palette: {
    primary: {
      main: '#00539CFF'
    },
    secondary: {
      main: "#EEA47FFF",
      second: "#F8E5E3"
    },
    overrides: {
      MuiButton: {
        secondary: {
          color: 'white',
        },
      },
    }
  }
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<>
  {/* //  <React.StrictMode>  */}
    {/* <ThemeProvider theme={customTheme}> */}
    <MantineProvider >

    <BrowserRouter>
      <CssBaseline />
      <Auth0Provider
        domain="matchboxxr.uk.auth0.com"
        clientId="VKfHmK4m5PuQUpdVlEr7iIMr9OMweMuH"
        authorizationParams={{
          // redirect_uri: 'http://localhost:3000/logging-in'
          redirect_uri: 'https://mbxr.io/logging-in'
        }}
      >
        <App />
      </Auth0Provider>
    </BrowserRouter> 

    </MantineProvider>

    {/* </ThemeProvider> */}
 {/* </React.StrictMode> */}
 </>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
