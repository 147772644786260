import React, {useEffect} from "react";
import Memberships from "../components/Memberships.js"
import { useAccountStore } from "../zustand/account";
import { getStripeCheckoutURL} from "../api";
import { LoadingOverlay, Card, Stack, Text, Group, Badge, Button, ActionIcon } from '@mantine/core';


export default function LogMeOutPage() {

    const { userStatus, logUserOut} = useAccountStore();

    const GridBackground = () => {
        return (
            <div style={{ position: "fixed", left: 0, top: 0, bottom: 0, zIndex: -1, width: "100%", height: "100vh", backgroundImage: 'url("/image/background-grid.png")', backgroundAttachment: "fixed" }}></div>

        )
    } 

    async function logout() {
        logUserOut() 
      }
     
    const handleLogoutClick = () => {
      logout().then(() => window.location.replace('https://mbxr.io/login'))
    
    }
 

    useEffect(() => {
        userStatus === "loggedIn-none" ?  handleLogoutClick() :
        userStatus === "loggedOut" ?  window.location.replace('https://mbxr.io/login') : window.location.replace('https://mbxr.io/projects')
    },[])

    return (
        <div>
            <GridBackground />
                <LoadingOverlay loaderProps={{ color: 'black' }} visible={true} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
        </div>
    )
}