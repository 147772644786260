import React, { useEffect, Suspense, useState } from "react";
import { Grid } from "@mui/material/";
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

import AppNavBar from "../components/AppNavBar";
import { postFigmaAuthData, getFigmaFrameFromLink } from "../api";

// we need to store the permission key in XANO and then it can be shared with any collaborating user

const AuthCallbackPage = () => {

    const [stateValid, setStateValid] = useState(null)
    const urlParams = new URLSearchParams(window.location.search);
    const stateParam = urlParams.get('state');
    const codeParam = urlParams.get('code');

    const [imageRequested, setImageRequested] = useState(false)

    const imageRequestClick = () => {
        getFigmaFrameFromLink().then(() => setImageRequested(!imageRequested))
    }
 


    const getAccessToken = () => {
  
        postFigmaAuthData({
            code:codeParam
            }).then((res) => {
                setStateValid(true)
                console.log(res)
            })
    }

    useEffect(() => {

        stateParam === localStorage.getItem('figmaState') ? getAccessToken() : setStateValid(false)
    }, []);

    const GridBackground = () => {
        return (
            <div style={{ position: "fixed", left: 0, top: 0, bottom: 0, zIndex: -1, width: "100%", height: "100vh", backgroundImage: 'url("/image/background-grid.png")', backgroundAttachment: "fixed" }}></div>

        )
    }



    return (
        <div>
            <Suspense fallback={<div>Loading... </div>}>
                <GridBackground />
                <Grid container>
                    <Grid item xs={12}>
                        <AppNavBar pageName={'authorised'} />
                    </Grid>
                    <Grid item xs={12} md={9} sx={{ mt: 8, mb: 4 }}>
                        <Box sx={{ marginLeft: 10, flexGrow: 1 }}>
                            <Typography component="h1" variant="h4" fontWeight={'700'}>
                                {!stateValid ? "Thinking..." : stateValid ? "Access authorised!" : "Uh oh something's wrong!"}
                            </Typography>
                        </Box>
                        <Box sx={{ marginLeft: 10, flexGrow: 1 }}>
                            <Typography component="p" variant="p" fontWeight={'700'}>
                                We have lift off! You can close this window. Jump back into your project to start using Figma frames in matchboxxr projects.
                                {/* {stateParam} & {localStorage.getItem('figmaState')} */}
                            </Typography>
                           
                        </Box>
                    </Grid>
                        {/* <Box sx={{ marginLeft: 10, flexGrow: 1 }}>
                            <button onClick={() => imageRequestClick()}>Request frame</button>
                        </Box> */}
                        {imageRequested && <img src={localStorage.getItem('figmaImage')} />}
                </Grid>
            </Suspense>
        </div>
    )
};

export default AuthCallbackPage;