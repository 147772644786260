import React, { useRef, useEffect, useState, Suspense, useCallback} from "react";
import { TransformControls, Image, PivotControls, useTexture, Plane } from "@react-three/drei";
import { useXR, useXREvent} from "@react-three/xr";
import { useObjectStore } from "../zustand/objects";
import { useThree } from "react-three-fiber";
import * as THREE from "three";
 
import debounce from 'lodash.debounce';


import VRControls from "./VRControls";
import {CurvedPanels, CurvedPanelsTexture} from "./ModelFiles/UI/CurvedPanels" 

export function ImageObject({ orbitControls, undoing, position, rotation, scale, id, currentObjectArtboard, objectsAreLoaded, object, colour, category, pose, image, imageSize, isLocked, matrixState, curved, curveAmount }) {

  const {
    updateObjectSelected,
    transformModeChange,
    selectedObjectID,
    transformMode,
    updateObjectMatrix,
    grouping,
    addToGroupedObjects,
    setIsDraggingModel,
    isDraggingModel,
    projectKey,
    arrangeUndoRedo
  } = useObjectStore();

  const transformControls = useRef();
  const transformChild = useRef();




  const grabcontrols = useRef()

  async function updatePositionVR() {
     // send current state to undoActions
     arrangeUndoRedo({
      updateType: "objectUpdate", 
      artboard: currentObjectArtboard, 
      objectID: id,
      undo:false, 
      projectKey: projectKey, 
      field: "matrixState",
      })
      // send update to zustand
    updateObjectMatrix({
      id,
      currentObjectArtboard,
      matrix: new THREE.Matrix4().copy(grabcontrols.current.matrixWorld),
      undo: false
    })
  }


const [vrHovered, setVrHovered] = useState(false)
const [vrSelected, setVrSelected] = useState(false)
const [vrDragging, setVrDragging] = useState(false)

const xr = useXR()

const handleVRSelectEnd = () => {
  if (xr.isPresenting) {
       if (vrSelected){
       updatePositionVR().then(() => grabcontrols.current.position.set(
         0,0,0
       )).then(() => grabcontrols.current.rotation.set(
        0,0,0
      )).then(() => setVrSelected(false))
}
    }
}

useXREvent('selectend', () => handleVRSelectEnd())


const handleDrag = useCallback(
  debounce(
    (e) =>
    selectedObjectID === id ? ObjectUpdate(e) : ''
  , 60)
  )

 
  // declare empty position, rotation and scale for .decompose()
  const pos = new THREE.Vector3()
  const rot = new THREE.Quaternion()
  const sca = new THREE.Vector3()

  // get local copy of matrixState
  const newMatrix = new THREE.Matrix4().copy(matrixState)

  // decompose newMatrix and apply to pos, rot and sca
  newMatrix.decompose(pos, rot, sca)
  // create matrix for gizmo that doesn't include scale, which is applied to the group instead
  const [stateGrabbed, setStateGrabbed] = useState(new THREE.Matrix4().copy(matrixState))
  const gizmoMatrix = new THREE.Matrix4().compose(pos, rot, new THREE.Vector3(1, 1, 1))
  // the below stops the object snapping back to old position while moving
  const [gizmoMatrixx, setGizmoMatrixx] = useState(gizmoMatrix)
  


  const [interimMatrix, setInterimMatrix] = useState(matrixState)

  const startDragging = () => {
    setIsDraggingModel(true)
  }

  const stopDragging = () => {
    setIsDraggingModel(false)
  }

  const handleDragging = useCallback(
    debounce(
      (e) =>
      selectedObjectID === id && setInterimMatrix(e)
    ,30))

  const ObjectUpdate = (e) => {
    const pos2 = new THREE.Vector3()
    const rot2 = new THREE.Quaternion()
    const sca2 = new THREE.Vector3()
    const toDecompose = new THREE.Matrix4().copy(e)
    toDecompose.decompose(pos2, rot2, sca2)
     // send current state to undoActions
     arrangeUndoRedo({
      updateType: "objectUpdate", 
      artboard: currentObjectArtboard, 
      objectID: id,
      undo:false, 
      projectKey: projectKey, 
      field: "matrixState",
      })
      // send update to zustand
    updateObjectMatrix({
      id,
      currentObjectArtboard,
      matrix: e,
      undo: false
    })}


  const missedShape = () => {
    !isLocked && !isDraggingModel && updateObjectSelected('0')
    transformModeChange({
      transformMode: "translate"
    })
  };


 
  const passdownFunctionEditor = () => {
    // !isDraggingModel && !isLocked ? !grouping ? updateObjectSelected(id) : addToGroupedObjects(id) : updateObjectSelected('0') && transformModeChange({
    //   transformMode: "translate"
    // })
    if (isDraggingModel === false) {
      !isLocked ? updateObjectSelected(id) : updateObjectSelected('0')  }
  }

    const imageRef = useRef()

    if (!objectsAreLoaded) {
        return
    } else {
        return (
            selectedObjectID === id ? !isLocked ? transformMode === "translate" ?
            <group onPointerMissed={(e) => {
                e.stopPropagation()
                missedShape()
              }}> 
                <PivotControls
                  depthTest={false}
                  lineWidth={5}
                  matrix={!isDraggingModel && new THREE.Matrix4().copy(matrixState)}
                  active={selectedObjectID === id ? [true, true, true] : [false, false, false]}
                  onDragStart={() => startDragging()}
                  onDragEnd={() => {
                    ObjectUpdate(interimMatrix)
                    stopDragging()}}
                  onDrag={(e) => handleDragging(e)}
                  // onDragEnd={() => handleDrag()}
                  // onDragEnd={() => selectedObjectID === id ? ObjectUpdate() : ''}
                  ref={transformControls}
                  visible={selectedObjectID === id ? true : false}
                  fixed={true}
                   scale={150}
                  annotations={false}
                >
                  <group ref={transformChild}
                  >
                        <Suspense fallback={null}>
                            {!curved ?
                              <DoubleSidedImage transparent opacity={1} imageSize={imageSize} url={image} />
                              : <CurvedPanelsTexture colour={colour} clickFunction={passdownFunctionEditor} imageTexture={image} curveAmount={curveAmount} width={imageSize.width / 1600} height={imageSize.height / 800}/>
                            }
                        </Suspense></group>
                </PivotControls></group> :
                <TransformControls
                    position={objectsAreLoaded && new THREE.Vector3().setFromMatrixPosition(matrixState)}
                    // rotation={objectsAreLoaded && new THREE.Euler().setFromRotationMatrix(matrixState)}
                    rotation={objectsAreLoaded && new THREE.Euler().setFromQuaternion(rot)}
                    scale={objectsAreLoaded && new THREE.Vector3().setFromMatrixScale(matrixState)}
                    showY={selectedObjectID === id ? true : false}
                    showX={selectedObjectID === id ? true : false}
                    showZ={selectedObjectID === id ? true : false}
                    // showX={selectedObjectID === id && (transformMode === "translate" || transformMode === "scale") ? true : (category === "shape") ? selectedObjectID === id && true : false}
                    // showZ={selectedObjectID === id && (transformMode === "translate" || transformMode === "scale") ? true : false}
                    translationSnap={0.125}
                    // rotationSnap={0.31416}
                    scaleSnap={0.025}
                    ref={transformControls}
                    mode={'scale'}
                >
                    <group ref={transformChild} onClick={() => passdownFunctionEditor()}>
                        <Suspense fallback={null}>
                        {!curved ?
                              <DoubleSidedImage transparent opacity={1} imageSize={imageSize} url={image} />
                              // <Image transparent opacity={1} ref={imageRef} position={[0, imageSize.height / 1600, 0]} scale={[imageSize.width / 800, imageSize.height / 800, 1]} url={image} />
                              : <CurvedPanelsTexture colour={colour} clickFunction={passdownFunctionEditor} imageTexture={image} curveAmount={curveAmount} width={imageSize.width / 1600} height={imageSize.height / 800}/>
                            }
                        </Suspense>
                    </group>
                </TransformControls>

                :
                <TransformControls
                    position={objectsAreLoaded && new THREE.Vector3().setFromMatrixPosition(matrixState)}
                    rotation={objectsAreLoaded && new THREE.Euler().setFromRotationMatrix(matrixState)}
                    scale={objectsAreLoaded && new THREE.Vector3().setFromMatrixScale(matrixState)}
                    showY={false}
                    showX={false}
                    showZ={false}
                    translationSnap={0.125}
                    // rotationSnap={0.31416}
                    scaleSnap={0.025}
                    ref={transformControls}
                    mode={transformMode}
                >
                    <Suspense fallback={null}>
                        <group onClick={() => passdownFunctionEditor()}>
                        {!curved ?
                              <DoubleSidedImage transparent opacity={1} imageSize={imageSize} url={image} />
                              : <CurvedPanelsTexture colour={colour} clickFunction={passdownFunctionEditor} imageTexture={image} curveAmount={curveAmount} width={imageSize.width / 1600} height={imageSize.height / 800}/>
                            }
                        </group>
                    </Suspense>
                </TransformControls>
                :
                <VRControls
                    isLocked={isLocked}
                    idprop={id}
                    currentObjectArtboard={currentObjectArtboard}
                    objectsAreLoaded={objectsAreLoaded}
                    matrixState={new THREE.Matrix4().copy(matrixState)}
                    rot={rot}
                    scale={sca}
                >
                    <Suspense fallback={null}>
                        <group onClick={() => passdownFunctionEditor()}>
                        {!curved ?
                              <DoubleSidedImage transparent opacity={1} imageSize={imageSize} url={image} />
                              : <CurvedPanelsTexture colour={colour} clickFunction={passdownFunctionEditor} imageTexture={image} curveAmount={curveAmount} width={imageSize.width / 1600} height={imageSize.height / 800}/>
                            }
                        </group>
                    </Suspense>
                </VRControls>
        ); 
    }
};


export function GroupedImageObject({ orbitControls, undoing, position, rotation, scale, id, currentObjectArtboard, objectsAreLoaded, object, colour, category, pose, image, imageSize, isLocked, matrixState, curved, curveAmount }) {

    const { scene } = useThree();
    const modelRef = useRef()


    useEffect(() => {
        Promise.resolve(scene.getObjectByName(id).remove(modelRef.current))
            .then(Promise.resolve(modelRef.current.applyMatrix4(matrixState)))
            .then(Promise.resolve(scene.getObjectByName(id).attach(modelRef.current)))
    }, []);

  


    const imageRef = useRef()
    if (!objectsAreLoaded) {
        return
    } else {
        return (
            <group
                ref={modelRef}
            // scale={scale}
            >
                <Suspense fallback={null}>
                    <group>
                    {!curved ?
                              <DoubleSidedImage transparent opacity={1} imageSize={imageSize} url={image} />
                              // <Image transparent opacity={1} ref={imageRef} position={[0, imageSize.height / 1600, 0]} scale={[imageSize.width / 800, imageSize.height / 800, 1]} url={image} />
                              : <CurvedPanelsTexture colour={colour} imageTexture={image} curveAmount={curveAmount} width={imageSize.width / 1600} height={imageSize.height / 800}/>
                            }
                    </group>
                </Suspense>
            </group>
        );
    }
};




// THE PLANE IN A NESTED OBJECT TO ALLOW USETEXTURE TO WORK

const DoubleSidedImage = ({url, imageSize}) => {

  const imageRef = useRef()

  const texture = useTexture(url)

 return (
  <Suspense fallback={null}>
  <Plane ref={imageRef} position={[0, imageSize.height / 1600, 0]} smoothness={4} scale={[imageSize.width / 800, imageSize.height / 800, 1]}>
  <meshPhongMaterial
    receiveShadow
    attach="material"
    opacity={1} transparent
    map={texture}
    side={THREE.DoubleSide}
  />
</Plane></Suspense>)
}


