import React, { useRef, Suspense, useState, useEffect } from "react";
import { useObjectStore } from "../zustand/objects";
import PreviewSplash from "./PreviewSplash";
import Cursor from "./Cursor";
import PlayerFigure from "./PlayerFigure";
import { ChangePreviewScene } from "./ChangePreviewScene";
import { CaptureHeadset, CaptureCamera } from "./CaptureHeadset";
import { PreviewObjectsMapped, VRPreviewObjectsMapped } from "./PreviewObjectsMapped";
import { TeleportFloorGrid, VRTeleportFloorGrid, ChangeSceneDesktop } from './TeleportFloorGrid'
import "../dot.css";

import { Card, Button, Text, Group, FileButton, ActionIcon, Center } from '@mantine/core';


import { Canvas, useThree } from "@react-three/fiber";
import { PointerLockControls, Sky, CubicBezierLine } from "@react-three/drei";
import { XR, Controllers, Hands, VRButton, useXREvent, useXR, TeleportationPlane } from "@react-three/xr";


const PreviewViewport = ({ presence, projectKey, artboards, guestMode, uniqueRealtimeId }) => {

  const [previewMode, setPreviewMode] = useState('desktop')
  const [modeSelectVisible, setModeSelectVisible] = useState(true)

  const canvasRef = useRef();
  const vRCanvasRef = useRef();
  const floorRef = useRef();


  const {
    
    previewCameraPosition,
    floorColour,
    objectsAreLoaded,
    currentObjectArtboard
  } = useObjectStore();

// - - - - cursors start

  const COLORS = [
    "#E57373",
    "#9575CD",
    "#4FC3F7",
    "#81C784",
    "#FFF176",
    "#FF8A65",
    "#F06292",
    "#7986CB",
  ];

  // - - - -  cursors end


  const controlsRef = useRef();

  const handleClick = (val) => {
    setModeSelectVisible(false)
    setPreviewMode(val)
  }

  const [showTiles, setShowTiles] = useState(false);

  let timer

  const handleLongPress = () => {
    timer = setTimeout(() => setShowTiles(true), 900)
  }

  const handleButtonRelease = () => {
    clearTimeout(timer)
    setShowTiles(false)
  }
  document.addEventListener("pointerdown", handleLongPress)
  document.addEventListener("pointerup", handleButtonRelease)

  function VRPreviewCameraFunction() {
    useXREvent('selectstart', (event) => handleLongPress())
    useXREvent('selectend', (event) => handleButtonRelease())
  }



  if (previewMode === "desktop") {
    return <>
      <Canvas
        ref={canvasRef}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
        camera={{
          position: [previewCameraPosition[0], 1.75, previewCameraPosition[2]],
          fov: 55
        }}
        frameloop="demand"
        shadows
        gl={{ preserveDrawingBuffer: true }}
      >
        <CaptureCamera uniqueRealtimeId={uniqueRealtimeId} projectKey={projectKey} currentObjectArtboard={currentObjectArtboard} />
        <Sky distance={4500000} sunPosition={[0, 1, 0]} inclination={0} azimuth={0.25} />
        <ambientLight intensity={1.5} />
          <group name="sun" position={[0, 10, 100]} rotation={[-Math.PI / 2, 0, 0]}>
            {/* <spotLight matrixAutoUpdate={false} intensity={150} shadow-mapSize-height={2048} shadow-mapSize-width={2048} /> */}
            <directionalLight matrixAutoUpdate={false} intensity={3} shadow-mapSize-height={2048} shadow-mapSize-width={2048} />
          </group>
        <PointerLockControls unlock={() => console.log("disconnect")} selector={"#splashy"} ref={controlsRef} />
        {objectsAreLoaded && <PreviewObjectsMapped projectKey={projectKey} guestMode={guestMode} artboards={artboards} />}
        <mesh
          receiveShadow
          rotation={[-Math.PI / 2, 0, 0]}
          position={[0, 0, 0]}
          ref={floorRef}
        >
          <planeGeometry attach="geometry" args={[50, 50]} />
          <meshStandardMaterial
            color={floorColour}
            attach="material"
            opacity={1}
          />
        </mesh>
        {showTiles ? <TeleportFloorGrid /> : ''}
        <ChangeSceneDesktop />
        <CursorsAndHeadsets presence={presence} currentObjectArtboard={currentObjectArtboard} COLORS={COLORS} projectKey={projectKey}/>
        {/* <Suspense fallback={null}>
          {presence && presence.map(({ connectionId, presence }) =>
            presence.cursor && presence.cursor.page === 'editor' && presence.cursor.artboard === currentObjectArtboard && <Cursor key={connectionId} rotation={presence.cursor.rotation} position={presence.cursor.position} color={COLORS[connectionId % COLORS.length]} mouse={presence.cursor.mouse} />
          )}</Suspense>
        <Suspense fallback={null}>
          {presence && presence.map(({ connectionId, presence }) =>
            presence.preview && presence.headset && presence.preview.page === 'preview' && presence.preview.artboard === currentObjectArtboard && <PlayerFigure key={connectionId} rotation={presence.headset.rotation} position={presence.headset.position} color={COLORS[connectionId % COLORS.length]} device={presence.preview.device} />
          )}</Suspense> */}
    
        
      </Canvas>
      {modeSelectVisible &&
        (
          <Card style={{
            zIndex: 400, borderRadius: 32, maxWidth: 600,
            maxHeight: 500, top: '50%', overflow: 'hidden',
            left: '50%', position: 'absolute', transform: 'translate(-50%, -50%)'
          }}>
            <h2>Welcome to preview mode</h2>
            <h4>VR teleporting</h4>
            <p>If you're in VR, point your controller at the floor and select areas to teleport to them. And hold down to enable scene change buttons.</p>
            <h4>Desktop/laptop teleporting</h4>
            <p>If you're on a computer, hold down left mouse button to enable teleportation. Use the scene change buttons the change scenes.</p>
            <p>You can also change scenes by selecting objects set as interactive.</p>
            <p>What device are you using? <i>You can refresh this page to change your selection.</i></p>
            <Group>
              <Button size="xl" onClick={() => handleClick("desktop")}
                style={{
                  backgroundColor: 'black', borderRadius: 32
                }}>Desktop</Button>
              <Button size="xl" onClick={() => handleClick("VR")}
                style={{
                  backgroundColor: 'black', borderRadius: 32
                }}>VR headset</Button>
            </Group>
          </Card>
        )}
      <PreviewSplash />
    </>

  }
  else if (previewMode === "VR") {
    return (
      <Suspense fallback={<div></div>}>
        <VRButton sessionInit={{ optionalFeatures: ['hand-tracking'] }} />
        <Canvas
          ref={vRCanvasRef}
          camera={{
            fov: 90
          }}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
          }}
          frameloop="demand"
          shadows
        >
          <XR>
            <CaptureHeadset currentObjectArtboard={currentObjectArtboard} />
            <TeleportationPlane leftHand={false} rightHand={true} maxDistance={10} size={0.5} />

            <VRPreviewCameraFunction />
            <Sky distance={450000} sunPosition={[0, 1, 0]} inclination={0} azimuth={0.25} />
            <ambientLight intensity={1.5} />
          <group name="sun" position={[0, 10, 100]} rotation={[-Math.PI / 2, 0, 0]}>
            {/* <spotLight matrixAutoUpdate={false} intensity={150} shadow-mapSize-height={2048} shadow-mapSize-width={2048} /> */}
            <directionalLight matrixAutoUpdate={false} intensity={3} shadow-mapSize-height={2048} shadow-mapSize-width={2048} />
          </group>
            <Controllers rayMaterial={{ color: 'blue' }} />
            <Hands />
            <group>
              <VRPreviewObjectsMapped projectKey={projectKey} guestMode={guestMode} />
            </group>
            <CursorsAndHeadsets presence={presence} currentObjectArtboard={currentObjectArtboard} COLORS={COLORS} projectKey={projectKey} />
            <mesh
              receiveShadow
              rotation={[-Math.PI / 2, 0, 0]}
              position={[0, 0, 0]}
            >
              <planeGeometry attach="geometry" args={[50, 50]} />
              <meshStandardMaterial
                color={floorColour}
                attach="material"
                opacity={1}
              />
            </mesh>
            <VRTeleportFloorGrid />
          </XR>
        </Canvas>
      </Suspense>
    )
  }


};

const CursorsAndHeadsets = ({presence, currentObjectArtboard, COLORS}) => {
  console.log(presence)
  return (
    <>
    {/* CURSORS */}
      <Suspense fallback={null}>
        {presence && presence.map(({update, uniqueRealtimeId }) =>
          update.cursor && update.cursor.page === 'editor' && update.cursor.artboard === currentObjectArtboard && <Cursor key={uniqueRealtimeId} rotation={update.cursor.rotation} position={update.cursor.position} color={COLORS[uniqueRealtimeId % COLORS.length]} mouse={update.cursor.mouse} />
        )}</Suspense>
        {/* DESKTOP HEADSETS */}
      <Suspense fallback={null}>
        {presence && presence.map(({update, uniqueRealtimeId}) =>
          update.preview && update.preview.page === 'preview' && update.preview.artboard === currentObjectArtboard && <PlayerFigure key={uniqueRealtimeId} rotation={update.preview.rotation} position={update.preview.position} color={COLORS[uniqueRealtimeId % COLORS.length]} device={update.preview.device} />
        )}</Suspense>
        {/* HEADSETS */}
      <Suspense fallback={null}>
        {presence && presence.map(({update, uniqueRealtimeId}) =>
          update.headset && update.headset.page === 'preview' && update.headset.artboard === currentObjectArtboard && <PlayerFigure key={uniqueRealtimeId} rotation={update.headset.rotation} position={update.headset.position} color={COLORS[uniqueRealtimeId % COLORS.length]} device={update.headset.device} />
        )}</Suspense>
        {/* CAPTURING EDITOR CURSOR */}
    </>
  )
}

export default PreviewViewport; 