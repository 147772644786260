import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete"
import { useObjectStore } from "../zustand/objects";

import { getUserFigmaFramesAPI } from "../api.js";

import shortid from "shortid";

const FigmaFramesAPIMapped = ({figmaURLs, readyToMap}) => {

    const {
        addObject,
        updateImageTexture,
        currentObjectArtboard,
        imagePurpose,
        setImagePurpose,
        arrangeUndoRedo
      } = useObjectStore();


    const [imageURLs, setImageURLs] = useState([])
    
    const [inputVal, setInputVal] = useState()

    

     // DELETE AN IMAGE RECORD
     const deleteImageRecord = (data) => {
        fetch(`https://x1hz-knoc-qcpv.e2.xano.io/api:aIaNUgG7/image/${data}`, {
            method: 'DELETE',
            headers: {
                "content-type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem('user')}`
            }
        })
        .then((response) => response.json())
        // .then((result) => {
        //     getUserImagesAPI(localStorage.getItem('user'))})
        .catch((error) => {
          console.error("Error:", error);
        });
    }


    const handleChange = (e) => {

        const formData = new FormData();

        const reader = new FileReader();
        reader.readAsDataURL(e)
        reader.onload = function () {
            formData.append("image", reader.result)
            setInputVal(formData)
          };
          reader.onerror = function (error) {
            console.log('Error: ', error);
          };
       
        
    }

    const handleSubmit = (e) => {
        // createNewImageRecord(inputVal)    
    }

    


    const addImageObject = (val, val2) => {
    const newID = shortid.generate()
    // send update to zustand first (so we have state to grab for undo array)
    addObject({ id: newID, currentObjectArtboard: currentObjectArtboard, undo: false, category: "image", image: val, imageSize: val2, object: "image", bGColour: null })
    // send current state to undoActions
    arrangeUndoRedo({
      updateType: "objectAdd",
      artboard: currentObjectArtboard,
      objectID: newID,
      undo: false
    })
        setImagePurpose(null)
    }

    const deleteImage = (id) => {
        deleteImageRecord(id)
    }


    return (<div>
        <div style={{ overflow: "scroll", maxHeight: "300px", paddingBottom: 10 }}><Grid container>
            {readyToMap && figmaURLs.map((figmaData, i) =>
                <Grid key={i} item xs={4}  >
                    <div style={{ height: "100px", overflow: "hidden", margin:5 }}>
                        <img alt={'uploaded content'} style={{ width: "100%" }} src={figmaData.URL} />
                    </div>
                    <div>
               <div style={{width: "100%"}}>
                <DeleteIcon style={{cursor: "pointer"}} onClick={() => deleteImage(figmaData.id)}>Delete</DeleteIcon>
                </div>
            </div>
                </Grid>
            )}
        </Grid>
        </div>
    </div>
    )
}

export default FigmaFramesAPIMapped